import React from "react";

const Feature = ({ feature1, feature2, feature3, feature4 }) => {
  return (
    <section className="feature-sec section-padding" id="purpose-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4">
            <h4>Objectives and Purpose</h4>
            <div className="feature-text">
              <div className="feature-text-box">
                <article>
                  <h4>1. Identify Goals</h4>
                  <p>{feature1}</p>
                </article>
              </div>
              <div className="feature-text-box">
                <article>
                  <h4>2. Target Audience</h4>
                  <p>{feature2}</p>
                </article>
              </div>
            </div>
          </div>
          <div className="col-md-4">
            <figure className="m-0">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo-coin.png"}
                alt="Logo"
                // className="rotating-image"
              />
            </figure>
          </div>
          <div className="col-md-4">
            <h4>Research and Analysis</h4>
            <div className="feature-text">
              <div className="feature-text-box">
                <article>
                  <h4>1. Market Research</h4>
                  <p>{feature3}</p>
                </article>
              </div>
              <div className="feature-text-box">
                <article>
                  <h4>2. Technical Feasibility</h4>
                  <p>{feature4}</p>
                </article>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Feature;
