import React from "react";
import { useNavigate } from "react-router-dom";

export const Hero = ({ title, subtitle }) => {
  const navigate = useNavigate();
  return (
    <div className="wraper-in">
      <section className="hero-sec">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-6 hero-content-main">
              <div className="hero-content">
                <div className="hero-text">
                  <h1>{title}</h1>
                  <p>{subtitle}</p>
                  <a
                    className="btn btn-primary"
                    onClick={() => {
                      navigate("/signup");
                    }}
                  >
                    Get started
                  </a>
                </div>
                <div className="hero-logos">
                  <h4>Trusted by industry leaders</h4>
                  <div className="hero-logos-in">
                    <a href="#">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/logo1.svg"
                        }
                        alt="Logo 1"
                      />
                    </a>
                    <a href="#">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/logo2.svg"
                        }
                        alt="Logo 2"
                      />
                    </a>
                    <a href="#">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/images/logo3.svg"
                        }
                        alt="Logo 3"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-6 presale-box">
              <figure>
                {/* <div className="image-container8955"> */}
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/banner-img.png"}
                  alt="Banner"
                  className="bouncing-image"
                />
                {/* </div> */}
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
