export const TokenPrice = 4.5;

// export const USDTAddress = "0x31CC0b1105A0344Dc0a4Cb72BD93806d485e7491"; // Sepolia Testnet
// export const WBTCAddress = "0xD9b8D242616c3516aA9b408095cDE283765D4B24"; // Sepolia Testnet
// export const CopperAddress = "0xF5ce4548C705eAfB31C57b9de785446ABB96B059"; // Sepolia Testnet
// export const PresaleAddress = "0x92e908d5fb6F4cBA0759e54b838b41CBc38126Ad"; // Sepolia Testnet
// export const StakingContractAddress =
//   "0xFFa7a9FB628d378A98Effc4070f906E6Ec4a486f"; // Sepolia Testnet

export const USDTAddress = "0xdAC17F958D2ee523a2206206994597C13D831ec7";
export const WBTCAddress = "0x2260FAC5E5542a773Aa44fBCfeDf7C193bc2C599";
export const CopperAddress = "0x855F7961F2Af40E441E34B84A8D48a632891032f";
export const PresaleAddress = "0xB6f8CC83D56AAC86E23F8aF7793E02EFFb4628E8";
export const StakingContractAddress =
  "0x2389F5D39b18732D8D68DD70625331c9B049Ab75";
