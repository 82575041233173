import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  forgotPasswordOtpSend,
  forgotPasswordOtpVerify,
} from "../services/api";

export const OtpVerification = () => {
  const location = useLocation();
  const user = location.state;

  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(4).fill("")); // For 4-digit OTP
  const [email, setEmail] = useState("");
  const inputRefs = useRef([]);

  const handleChange = (element, index) => {
    const { value } = element;
    if (isNaN(value)) return; // Allow only numeric input
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field
    if (index < otp.length - 1 && value !== "") {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.keyCode === 8 && !otp[index] && index !== 0) {
      // Handle backspace key
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerify = async () => {
    const output = await forgotPasswordOtpVerify(
      otp.join(""),
      user.resetPasswordToken
    );
    console.log("output from verify otp screen: ", output);

    if (output?.user) {
      const user = { ...output.user, otp: otp.join("") };
      navigate("/reset-password", { state: user });
    } else {
      console.log(output.message);
    }
  };

  const handleResendOtp = async () => {
    const output = await forgotPasswordOtpSend(email);
    console.log("output from resend otp: ", output);
  };

  useEffect(() => {
    setEmail(user?.email);
  }, [user]);

  return (
    <div>
      <div className="login-wraper-in" style={{ height: "100vh" }}>
        <div className="login-left">
          <figure>
            <img src="assets/images/logo-coin.png" />
          </figure>
          <figcaption>
            <h4>Empower users to buy crypto</h4>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.{" "}
            </p>
          </figcaption>
        </div>
        <div className="login-right">
          <div className="login-right-in">
            <div className="login-head">
              <a
                onClick={() => {
                  navigate("/");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <img src="assets/images/logo-white.svg" />
              </a>
              <div className="login-head-text">
                <h4>OTP Verification</h4>
                <p>
                  We have sent a verification code to your email address valid
                  for 10 minutes only.
                </p>
                <p>
                  <span>{email}</span>
                </p>
              </div>
            </div>
            <div className="login-body">
              <div className="form-box">
                <div className="otp-box">
                  {otp.map((data, index) => (
                    <div className="form-group form-gry" key={index}>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          maxLength="1"
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          ref={(el) => (inputRefs.current[index] = el)}
                        />
                      </div>
                    </div>
                  ))}
                </div>
                <div className="form-btn">
                  <button className="btn" onClick={handleVerify}>
                    Verify{" "}
                  </button>
                </div>
                <div className="last-line mt-3 text-center">
                  <span>
                    Didn’t get the OTP?{" "}
                    <a
                      className="link-text"
                      onClick={handleResendOtp}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Resend
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
