import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { forgotPasswordOtpSend } from "../services/api";
import { Loader } from "../components/Loader";

export const ForgotPassword = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const validateEmail = (email) => {
    const re =
      // Regular expression for email validation
      /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };

  const handleForgetPassword = async () => {
    setLoading(true);
    if (!email) {
      setErrors({ email: "Email address is required" });
      setLoading(false);
      return;
    } else if (!validateEmail(email)) {
      setErrors({ email: "Invalid email address" });
      setLoading(false);
      return;
    }

    const output = await forgotPasswordOtpSend(email);
    if (output?.user) {
      const user = output?.user;
      navigate("/otp-verification", { state: user });
    } else {
      console.log(output.message);
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="login-wraper-in" style={{ height: "100vh" }}>
        <div className="login-left">
          <figure>
            <img src="assets/images/logo-coin.png" />
          </figure>
          <figcaption>
            <h4>Empower users to buy crypto</h4>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.{" "}
            </p>
          </figcaption>
        </div>
        <div className="login-right">
          <div className="login-right-in">
            <div className="login-head">
              <a
                onClick={() => {
                  navigate("/");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/logo-white.svg"}
                  alt="Logo"
                  width={250}
                  height={80}
                />
              </a>
              <div className="login-head-text">
                <h4>Forgot password</h4>
                <p>
                  Enter your email address, and we will send you an OTP to reset
                  your password{" "}
                </p>
              </div>
            </div>
            <div className="login-body">
              <div className="form-box">
                <div className="form-group form-gry">
                  <label>Email Address</label>
                  <div className="input-group">
                    <div className="input-group-icon">
                      <i className="ti ti-user"></i>
                    </div>
                    <input
                      type="text"
                      maxLength={50}
                      className={`form-control ${
                        errors.email ? "is-invalid" : ""
                      }`}
                      placeholder="Enter email address"
                      value={email}
                      onChange={(e) => {
                        setEmail(e.target.value);
                        setErrors({});
                      }}
                    />
                  </div>
                  {errors.email && (
                    <small className="text-danger">{errors.email}</small>
                  )}
                </div>
                <div className="form-btn">
                  <button className="btn" onClick={handleForgetPassword}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
