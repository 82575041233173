import React from "react";

const About = ({ title, subtitle }) => {
  return (
    <section className="about-sec section-padding" id="about-section">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-md-4">
            <figure className="m-0">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/about.png"}
                alt="About"
                className="bouncing-image"
              />
            </figure>
          </div>
          <div className="col-md-6 ms-auto">
            <div className="heading-text">
              <h4>{title}</h4>
              <p
                dangerouslySetInnerHTML={{ __html: subtitle }}
                style={{
                  fontWeight: "400px",
                  fontSize: "17px",
                }}
              ></p>

              {/* {subtitle} */}
              {/* <a href="#" className="btn btn-primary">
                Read More
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
