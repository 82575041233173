import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { registrationOtpVerify } from "../services/api";
import { alertMsg, trimData } from "../services/help";
import { Loader } from "../components/Loader";
import axios from "axios";

export const ResOtpVerification = () => {
  // const location = useLocation();
  // const user = location.state;
  // const data = location.state;
  const navigate = useNavigate();
  const [otp, setOtp] = useState(new Array(4).fill("")); // For 4-digit OTP
  const [email, setEmail] = useState("");
  const inputRefs = useRef([]);
  const [loading, setLoading] = useState(false);
  const [currentUser, setCurrentUser] = useState({});

  const handleChange = (element, index) => {
    const { value } = element;
    if (isNaN(value)) return; // Allow only numeric input
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);

    // Move to the next input field
    if (index < otp.length - 1 && value !== "") {
      inputRefs.current[index + 1].focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.keyCode === 8 && !otp[index] && index !== 0) {
      // Handle backspace key
      inputRefs.current[index - 1].focus();
    }
  };

  const handleVerify = async () => {
    const createdAt = new Date(currentUser?.user?.createdAt); // Ensure createdAt is a Date object
    const currentTime = new Date();
    const timeDifference = currentTime - createdAt;

    if (timeDifference > 9.5 * 60 * 1000) {
      // Approx 10 minutes
      alertMsg("error", "OTP verification time expired");
      return;
    }

    setLoading(true);
    const output = await registrationOtpVerify(
      otp.join(""),
      currentUser?.user?.resetPasswordToken
    );
    console.log("output from verify otp screen: ", output);

    console.log(
      "output from user login : ",
      output,
      output?.user?.resetPasswordToken
    );
    // Example logic to handle login response
    if (output?.user?.resetPasswordToken) {
      localStorage.setItem("accessToken", output?.user?.resetPasswordToken);
      navigate("/dashboard");
    } else {
      console.log(output.message);
    }
    setLoading(false);
  };

  useEffect(() => {
    const signupInfo = JSON.parse(sessionStorage.getItem("signupInfo"));
    setCurrentUser(signupInfo);
    setEmail(signupInfo?.user?.email);
  }, []);

  const handleResendOtp = async () => {
    try {
      const API = process.env.REACT_APP_API_URL;
      const endPoint = API + "/api/v1/signup";

      const response = await axios.post(endPoint, currentUser?.data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("response : ", response);

      if (response?.data?.user) {
        let token = response?.data?.user?.resetPasswordToken;

        localStorage.setItem("accessToken", token);
      } else {
        console.log(response?.data?.message, "response.data.message");
      }
      alertMsg("success", "OTP Resend to registration email");
    } catch (error) {
      console.error("Signup error: ", error);
      alertMsg("error", error?.response?.data?.message);
    }
  };

  console.log(" from OTP Verify , email : ", email);

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="login-wraper-in" style={{ height: "100vh" }}>
        <div className="login-left">
          <figure>
            <img src="assets/images/logo-coin.png" />
          </figure>
          <figcaption>
            <h4>Empower users to buy crypto</h4>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.{" "}
            </p>
          </figcaption>
        </div>
        <div className="login-right">
          <div className="login-right-in">
            <div className="login-head">
              <a
                onClick={() => {
                  navigate("/");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <img src="assets/images/logo-white.svg" />
              </a>
              <div className="login-head-text">
                <h4>OTP Verification</h4>
                <p>
                  We have sent a verification code to your Email address valid
                  for 10 minutes only.
                </p>
                <p>
                  <span>{trimData(email, 50)}</span>
                </p>
              </div>
            </div>
            <div className="login-body">
              <div className="form-box">
                <div className="otp-box">
                  {otp.map((data, index) => (
                    <div className="form-group form-gry" key={index}>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          maxLength="1"
                          value={data}
                          onChange={(e) => handleChange(e.target, index)}
                          onKeyDown={(e) => handleKeyDown(e, index)}
                          ref={(el) => (inputRefs.current[index] = el)}
                        />
                      </div>
                    </div>
                  ))}
                </div>

                <div className="form-btn">
                  <button className="btn" onClick={handleVerify}>
                    Verify{" "}
                  </button>
                </div>
                <div className="last-line mt-3 text-center">
                  <span>
                    Didn’t get the OTP?{" "}
                    <a
                      className="link-text"
                      onClick={handleResendOtp}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Resend
                    </a>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
