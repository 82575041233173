import React, { useEffect, useState } from "react";
// import { DashHeader } from "./DashHeader";
import Footer from "../landingPage/Footer";
import { getAllNotifications } from "../../services/api";
import { Loader } from "../Loader";
import { formatDate, remove_p_br_Tags, trimData } from "../../services/help";
import { NotificationModal } from "../Modal/NotificationModal";
import Header from "../landingPage/Header";

export const Notifications = () => {
  const [notification, setNotification] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [limit, setLimit] = useState(5);
  const [isOpen, setIsOpen] = useState(false);
  const [tx, setTx] = useState();

  useEffect(() => {
    async function fetchData() {
      try {
        setNotification(
          await getAllNotifications({
            page: currentPage,
            limit,
          })
        );
      } catch (error) {
        console.log("Error fetching notifications: ", error);
      }
    }

    fetchData();
  }, [currentPage, isOpen]);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  if (!notification) {
    return <Loader />;
  }
  const totalPages = notification.notifications.totalPages;

  console.log("notification : ", notification, totalPages);

  return (
    <>
      <div className="dashboard-bg">
        <Header />
        <div>
          <div className="wraper-in">
            <div className="container">
              <div
                className="dashboard-content-box notifications-content-bg"
                style={{
                  minHeight: "400px",
                }}
              >
                <h2>Notifications</h2>
                <div
                  className="notifications-list"
                  style={{
                    marginBottom: "20px",
                  }}
                >
                  <ul>
                    {notification?.notifications?.docs.length > 0 ? (
                      notification?.notifications?.docs.map((item, index) => (
                        <li
                          key={index}
                          style={
                            !item?.isRead == true
                              ? {
                                  background: "#d88638",
                                }
                              : null
                          }
                          onClick={(e) => {
                            e.preventDefault();
                            setTx(item);
                            toggle();
                          }}
                        >
                          <div className="notifications-left-box">
                            <h3>
                              {trimData(item?.title, 80)} {item?.isRead}
                            </h3>
                            <p>{trimData(remove_p_br_Tags(item?.body), 100)}</p>
                          </div>

                          <div
                            className="notifications-right-box"
                            style={{
                              width: "180px",
                              ...(!item?.isRead ? { color: "#fff" } : {}),
                            }}
                          >
                            {formatDate(item?.createdAt)}
                          </div>
                        </li>
                      ))
                    ) : (
                      <p
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          height: "100%",
                          color: "#ccc",
                        }}
                      >
                        Notification not found
                      </p>
                    )}
                  </ul>
                </div>
                {totalPages > 1 && (
                  <nav aria-label="Page navigation">
                    <ul className="pagination">
                      <li
                        className={`page-item ${
                          currentPage === 1 ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(currentPage - 1)}
                          disabled={currentPage === 1}
                        >
                          <i className="ti ti-chevron-left"></i>
                        </button>
                      </li>
                      {[...Array(totalPages).keys()].map((pageNumber) => (
                        <li
                          key={pageNumber}
                          className={`page-item ${
                            pageNumber + 1 === currentPage ? "active" : ""
                          }`}
                        >
                          <button
                            className="page-link"
                            onClick={() => setCurrentPage(pageNumber + 1)}
                          >
                            {pageNumber + 1}
                          </button>
                        </li>
                      ))}
                      <li
                        className={`page-item ${
                          currentPage === totalPages ? "disabled" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(currentPage + 1)}
                          disabled={currentPage === totalPages}
                        >
                          <i className="ti ti-chevron-right"></i>
                        </button>
                      </li>
                    </ul>
                  </nav>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      {/* Notification Modal  */}
      <NotificationModal tx={tx} isOpen={isOpen} toggle={toggle} />
    </>
  );
};
