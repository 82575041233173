import React, { useEffect, useState } from "react";
import { getCoinPrice } from "../../services/help";

const Coin = () => {
  const [coinPrice, setCoinPrice] = useState();
  useEffect(() => {
    async function fetchData() {
      try {
        const token = await getCoinPrice();
        setCoinPrice(token);
        console.log("token", token);
      } catch (err) {
        console.log(err);
      }
    }

    fetchData();
  }, []);
  return (
    <section className="coin-info section-padding">
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <article>
              <div className="coin-sec-head">
                <figure>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/btc.svg"}
                    alt="BTC"
                  />
                </figure>
                <figcaption>
                  <span>BTC</span>
                  <h4>Bitcoin</h4>
                </figcaption>
              </div>
              <div className="coin-sec-body">
                <div className="coin-left">
                  <h4>{coinPrice?.bitcoin?.usd} USD</h4>
                  <div className="value-up-down">
                    {coinPrice?.bitcoin?.usd_24h_change > 0 ? (
                      <div className="updown-icon updown-icon-green">
                        <i className="ti ti-arrow-up"></i>
                      </div>
                    ) : (
                      <div className="updown-icon">
                        <i className="ti ti-arrow-down"></i>
                      </div>
                    )}
                    <span>
                      {coinPrice?.bitcoin?.usd_24h_change
                        .toString()
                        .substring(0, 6)}
                      %{" "}
                    </span>
                  </div>
                </div>
                <div className="coin-right">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/chart${
                        coinPrice?.bitcoin?.usd_24h_change > 0 ? "-green" : ""
                      }.png`
                    }
                    alt="Chart"
                  />
                </div>
              </div>
            </article>
          </div>
          {/* Repeat the same structure for other coins */}
          <div className="col-md-4">
            <article>
              <div className="coin-sec-head">
                <figure>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/usdt.svg"}
                    alt="USDT"
                  />
                </figure>
                <figcaption>
                  <span>USDT</span>
                  <h4>Tether</h4>
                </figcaption>
              </div>
              <div className="coin-sec-body">
                <div className="coin-left">
                  <h4>{coinPrice?.tether?.usd} USD</h4>
                  <div className="value-up-down">
                    {coinPrice?.tether?.usd_24h_change > 0 ? (
                      <div className="updown-icon updown-icon-green">
                        <i className="ti ti-arrow-up"></i>
                      </div>
                    ) : (
                      <div className="updown-icon">
                        <i className="ti ti-arrow-down"></i>
                      </div>
                    )}

                    <span>
                      {coinPrice?.tether?.usd_24h_change
                        .toString()
                        .substring(0, 6)
                        .toString()
                        .substring(0, 6)}
                      %{" "}
                    </span>
                  </div>
                </div>
                <div className="coin-right">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/chart${
                        coinPrice?.tether?.usd_24h_change > 0 ? "-green" : ""
                      }.png`
                    }
                    alt="Chart"
                  />
                </div>
              </div>
            </article>
          </div>
          {/* Repeat the same structure for other coins */}
          <div className="col-md-4">
            <article>
              <div className="coin-sec-head">
                <figure>
                  <img
                    src={process.env.PUBLIC_URL + "/assets/images/eth.svg"}
                    alt="ETH"
                  />
                </figure>
                <figcaption>
                  <span>ETH</span>
                  <h4>Ethereum</h4>
                </figcaption>
              </div>
              <div className="coin-sec-body">
                <div className="coin-left">
                  <h4>{coinPrice?.ethereum?.usd} USD</h4>
                  <div className="value-up-down">
                    {coinPrice?.ethereum?.usd_24h_change > 0 ? (
                      <div className="updown-icon updown-icon-green">
                        <i className="ti ti-arrow-up"></i>
                      </div>
                    ) : (
                      <div className="updown-icon">
                        <i className="ti ti-arrow-down"></i>
                      </div>
                    )}
                    <span>
                      {coinPrice?.ethereum?.usd_24h_change
                        .toString()
                        .substring(0, 6)}{" "}
                      %{" "}
                    </span>
                  </div>
                </div>
                <div className="coin-right">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      `/assets/images/chart${
                        coinPrice?.ethereum?.usd_24h_change > 0 ? "-green" : ""
                      }.png`
                    }
                    alt="Chart"
                  />
                </div>
              </div>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Coin;
