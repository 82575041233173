import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Loader } from "../components/Loader";
import { alertMsg } from "../services/help";

export const Signup = () => {
  const [loading, setLoading] = useState(false);
  const [showPass, setShowPass] = useState(false);
  const [showPass2, setShowPass2] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();

  const onSubmit = async (data) => {
    setLoading(true);
    setPasswordMatchError("");

    if (data.password !== data.confirmPassword) {
      setPasswordMatchError("Confirm passwords do not match");
      setLoading(false);
      return;
    }

    console.log(data);

    try {
      const API = process.env.REACT_APP_API_URL;
      const endPoint = API + "/api/v1/signup";
      console.log();

      const response = await axios.post(endPoint, data, {
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("response : ", response);

      if (response?.data?.user) {
        let token = response?.data?.user?.resetPasswordToken;

        const user = response?.data?.user;
        localStorage.setItem("accessToken", token);

        const signupInfo = {
          user: user,
          data: data,
        };
        sessionStorage.setItem("signupInfo", JSON.stringify(signupInfo));

        navigate("/registration-otp-verification");
      } else {
        console.log(response?.data?.message, "response.data.message");
      }
      alertMsg("success", response?.data?.message);
    } catch (error) {
      console.error("Signup error: ", error);
      alertMsg("error", error?.response?.data?.message);
    }
    setLoading(false);
  };

  const handleLogin = () => {
    navigate("/login");
  };

  const togglePasswordVisibility = () => {
    setShowPass(!showPass); // Correct way to toggle state
  };

  const getMaxDate = () => {
    const currentDate = new Date();
    const minYear = currentDate.getFullYear() - 15;
    const minMonth = String(currentDate.getMonth() + 1).padStart(2, "0"); // Months are 0-based, so add 1
    const minDay = String(currentDate.getDate()).padStart(2, "0");
    return `${minYear}-${minMonth}-${minDay}`;
  };

  console.log("password : " + password);

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="login-wraper" style={{ height: "100vh" }}>
        <div className="login-wraper-in">
          <div className="login-left">
            <figure>
              <img
                src={process.env.PUBLIC_URL + "assets/images/logo-coin.png"}
              />
            </figure>
            <figcaption>
              <h4>Empower users to buy crypto</h4>
              <p>
                It is a long established fact that a reader will be distracted
                by the readable content of a page when looking at its layout.{" "}
              </p>
            </figcaption>
          </div>
          <div className="login-right">
            <div className="login-right-in singup-width">
              <div className="login-head">
                <a
                  onClick={() => {
                    navigate("/");
                  }}
                  style={{
                    cursor: "pointer",
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/logo-white.svg"
                    }
                    alt="Logo"
                    width={250}
                    height={80}
                  />
                </a>
                <div className="login-head-text">
                  <h4>Get Started with CUCC</h4>
                  <p>Please enter your details to create account. </p>
                </div>
              </div>
              <div className="login-body">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-box">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group form-gry">
                          <label>First Name</label>
                          <div className="input-group">
                            <div className="input-group-icon">
                              <i className="ti ti-user"></i>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter first name"
                              {...register("firstname", {
                                required: "First name is required",
                                maxLength: {
                                  value: 25,
                                  message:
                                    "First name cannot exceed 25 characters",
                                },
                                validate: (value) =>
                                  value.trim() !== "" ||
                                  "First name cannot be empty or spaces",
                              })}
                            />
                          </div>
                          {errors.firstname && (
                            <small className="text-danger">
                              {errors.firstname.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-gry">
                          <label>Last Name</label>
                          <div className="input-group">
                            <div className="input-group-icon">
                              <i className="ti ti-user"></i>
                            </div>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Enter last name"
                              {...register("lastname", {
                                required: "Last name is required",
                                maxLength: {
                                  value: 25,
                                  message:
                                    "Last name cannot exceed 25 characters",
                                },
                                validate: (value) =>
                                  value.trim() !== "" ||
                                  "Last name cannot be empty or spaces",
                              })}
                            />
                          </div>
                          {errors.lastname && (
                            <small className="text-danger">
                              {errors.lastname.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-gry">
                          <label>Email Address</label>
                          <div className="input-group">
                            <div className="input-group-icon">
                              <i className="ti ti-mail"></i>
                            </div>
                            <input
                              type="email"
                              className="form-control"
                              placeholder="Enter email address"
                              {...register("email", {
                                required: "Email address is required",
                                maxLength: {
                                  value: 50,
                                  message: "Email cannot exceed 50 characters",
                                },

                                pattern: {
                                  value:
                                    /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                                  message: "Enter a valid email address",
                                },
                              })}
                            />
                          </div>
                          {errors.email && (
                            <small className="text-danger">
                              {errors.email.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-gry">
                          <label>Date Of Birth</label>
                          <div className="input-group">
                            <div className="input-group-icon">
                              <i className="ti ti-cake"></i>
                            </div>
                            <input
                              type="date"
                              max={getMaxDate()}
                              className="form-control"
                              {...register("dob", {
                                required: "Date of birth is required",
                              })}
                            />
                            <style>
                              {`
          input[type="date"]::-webkit-calendar-picker-indicator {
            filter: invert(100%);
          }
          input[type="date"]::-webkit-calendar-picker-indicator:hover {
            filter: invert(100%);
          }
        `}
                            </style>
                          </div>
                          {errors.dob && (
                            <small className="text-danger">
                              {errors.dob.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-gry">
                          <label>Password</label>
                          <div className="input-group">
                            <div className="input-group-icon">
                              <i className="ti ti-lock"></i>
                            </div>
                            <input
                              type={`${!showPass ? "password" : "text"}`}
                              className="form-control"
                              onChange={(e) => {
                                setPassword(e.target.value);
                              }}
                              placeholder="Enter password"
                              {...register("password", {
                                required: "Password is required",
                                minLength: {
                                  value: 8,
                                  message:
                                    "Password must be at least 8 characters long",
                                },
                                validate: {
                                  capitalLetter: (value) =>
                                    /[A-Z]/.test(value) ||
                                    "Password must include at least one capital letter",

                                  smallLetter: (value) =>
                                    /[a-z]/.test(value) ||
                                    "Password must include at least one small letter",
                                  hasNumber: (value) =>
                                    /\d/.test(value) ||
                                    "Password must include at least one number",
                                  hasSpecialChar: (value) =>
                                    /[@$!%*?&]/.test(value) ||
                                    "Password must include at least one special character",
                                  noSpaces: (value) =>
                                    /^\S*$/.test(value) ||
                                    "Password must not contain spaces",
                                },
                              })}
                            />
                            <div
                              style={{
                                position: "absolute",
                                right: "14px",
                                top: "7px",
                                fontSize: "24px",
                                color: "var(--white)",
                                zIndex: 9,
                                background: "transparent",
                              }}
                              onClick={togglePasswordVisibility}
                            >
                              <i
                                className={`ti ti-eye${
                                  !showPass ? "-off" : ""
                                }`}
                              ></i>
                            </div>
                          </div>
                          {errors.password && (
                            <small className="text-danger">
                              {errors.password.message}
                            </small>
                          )}
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-gry">
                          <label>Confirm Password</label>
                          <div className="input-group">
                            <div className="input-group-icon">
                              <i className="ti ti-lock"></i>
                            </div>
                            <input
                              type={`${!showPass2 ? "password" : "text"}`}
                              className="form-control"
                              placeholder="Enter confirm password"
                              {...register("confirmPassword", {
                                required: "Confirm password is required",
                              })}
                            />

                            <div
                              style={{
                                position: "absolute",
                                right: "14px",
                                top: "7px",
                                fontSize: "24px",
                                color: "var(--white)",
                                zIndex: 9,
                                background: "transparent",
                              }}
                              onClick={() => {
                                setShowPass2(!showPass2);
                              }}
                            >
                              <i
                                className={`ti ti-eye${
                                  !showPass2 ? "-off" : ""
                                }`}
                              ></i>
                            </div>
                          </div>
                          {errors.confirmPassword && (
                            <small className="text-danger">
                              {errors.confirmPassword.message}
                            </small>
                          )}
                          {passwordMatchError && (
                            <small className="text-danger">
                              {passwordMatchError}
                            </small>
                          )}
                        </div>
                      </div>
                      {/* <div className="col-md-12">
                        <div className="form-group form-gry password-view-input">
                          <label>ID Verification Document </label>
                          <div className="input-group">
                            <div className="input-group-icon">
                              <i className="ti ti-file-plus"></i>
                            </div>
                            <input
                              type="file"
                              className="form-control"
                              accept="image/*"
                              {...register("keyDoc", {
                                required: "KYC Document is required",
                              })}
                            />
                            <div
                              className="password-eye-icon"
                              style={{ pointerEvents: "none" }}
                            >
                              <i className="ti ti-upload"></i>
                            </div>
                          </div>
                          {errors.keyDoc && (
                            <small className="text-danger">
                              {errors.keyDoc.message}
                            </small>
                          )}
                        </div>
                      </div> */}
                    </div>
                    <div className="form-btn">
                      <button type="submit" className="btn">
                        {loading ? "Loading..." : "Submit"}
                      </button>
                    </div>
                    <div className="last-line mt-3 text-center">
                      <span>
                        Already have an account?{" "}
                        <a
                          onClick={handleLogin}
                          className="link-text"
                          style={{
                            cursor: "pointer",
                          }}
                        >
                          Login
                        </a>
                      </span>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
