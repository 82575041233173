import React from "react";
import { formatDate } from "../../services/help";

export const TransactionModal = ({ tx, isOpen, toggle, type }) => {
  return isOpen ? (
    <div
      className="modal fade transaction-approved-modal show"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={isOpen ? { display: "block" } : "none"}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Transaction approved note
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <div className="modal-body">
            <div className="transaction-info-list">
              <ul>
                <li>
                  <h5>Transaction ID</h5>
                  <span>{tx?._id}</span>
                </li>
                <li>
                  <h5>{type == "buy" ? "Amount" : "Tokens"}</h5>
                  <span>
                    {type == "buy" ? tx?.amount : tx?.token}{" "}
                    {type == "buy" ? tx?.buyMethod : "CUCC"}
                  </span>
                </li>
                <li>
                  <h5>Destination address</h5>
                  <span>{tx?.depositWallet ?? tx?.wallet}</span>
                </li>
                <li>
                  <h5>{type == "buy" ? "Buy Method" : "Durations"}</h5>
                  <span>{tx?.buyMethod ?? tx?.durations}</span>
                </li>
                <li>
                  <h5>Date</h5>
                  <span>{formatDate(tx?.createdAt)}</span>
                </li>

                <li>
                  <h5>Status</h5>
                  <button className="status-tag">Success</button>
                </li>
              </ul>
            </div>
          </div>
          <button
            className="explorer-btn"
            onClick={() => {
              const url = `https://etherscan.io/tx/${tx?.transactionHash}`;
              console.log("url is : " + url);
              window.open(url, "_blank");
              toggle();
            }}
          >
            View on etherscan
          </button>
        </div>
      </div>
    </div>
  ) : null; // If isOpen is false, don't render anything
};
