import React, { useEffect, useState } from "react";
import Header from "./landingPage/Header";
import { Hero } from "./landingPage/Hero";
import About from "./landingPage/About";
import Coin from "./landingPage/Coin";
import Feature from "./landingPage/Feature";
import Team from "./landingPage/Team";
import Faq from "./landingPage/Faq";
import { Inquiry } from "./landingPage/Inquiry";
import Footer from "./landingPage/Footer";
import { NewFeature } from "./landingPage/NewFeature";
import { getAllContent } from "../services/api";
import { removeHTMLTags } from "../services/help";
import { Loader } from "./Loader";

export const Home = () => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({
    title: "",
    subtitle: "",
    aboutTitle: "",
    aboutSubtitle: "",
    feature1: "",
    feature2: "",
    feature3: "",
    feature4: "",
    f2title1: "",
    f2subtitle1: "",
    f2title2: "",
    f2subtitle2: "",
  });

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const output = await getAllContent();
        console.log("get content output:", output);

        if (output?.contents?.length) {
          const newData = {};

          output.contents.forEach((content) => {
            console.log("content:", content);

            switch (content.key) {
              case "title":
                newData.title = removeHTMLTags(
                  content.value || newData.title || ""
                );
                break;
              case "subtitle":
                newData.subtitle = removeHTMLTags(
                  content.value || newData.subtitle || ""
                );
                break;
              case "aboutTitle":
                newData.aboutTitle = removeHTMLTags(
                  content.value || newData.aboutTitle || ""
                );
                break;
              case "aboutSubtitle":
                newData.aboutSubtitle = removeHTMLTags(
                  content.value || newData.aboutSubtitle || ""
                );
                break;
              case "feature1":
                newData.feature1 = removeHTMLTags(
                  content.value || newData.feature1 || ""
                );
                break;
              case "feature2":
                newData.feature2 = removeHTMLTags(
                  content.value || newData.feature2 || ""
                );
                break;
              case "feature3":
                newData.feature3 = removeHTMLTags(
                  content.value || newData.feature3 || ""
                );
                break;
              case "feature4":
                newData.feature4 = removeHTMLTags(
                  content.value || newData.feature4 || ""
                );
                break;
              case "f2title1":
                newData.f2title1 = removeHTMLTags(
                  content.value || newData.f2title1 || ""
                );
                break;
              case "f2subtitle1":
                newData.f2subtitle1 = removeHTMLTags(
                  content.value || newData.f2subtitle1 || ""
                );
                break;
              case "f2title2":
                newData.f2title2 = removeHTMLTags(
                  content.value || newData.f2title2 || ""
                );
                break;
              case "f2subtitle2":
                newData.f2subtitle2 = removeHTMLTags(
                  content.value || newData.f2subtitle2 || ""
                );
                break;
              default:
                break;
            }
          });

          setData(newData);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        // Handle errors here, such as displaying an error message or setting a state variable
      }
    };

    fetchData(); // Call the fetchData function
    setLoading(false);
  }, [loading]);

  console.log("get all content: data", data);

  return (
    <>
      {loading ? <Loader /> : null}
      <Header />
      <Hero title={data?.title} subtitle={data?.subtitle} />
      <About title={data?.aboutTitle} subtitle={data?.aboutSubtitle} />
      <Coin />
      <Feature
        feature1={data?.feature1}
        feature2={data?.feature2}
        feature3={data?.feature3}
        feature4={data?.feature4}
      />
      <NewFeature
        f2title1={data?.f2title1}
        f2subtitle1={data?.f2subtitle1}
        f2title2={data?.f2title2}
        f2subtitle2={data?.f2subtitle2}
      />
      {/* <Team /> */}
      <Faq />
      <Inquiry />
      <Footer />
    </>
  );
};

export default Home;
