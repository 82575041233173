import React, { useEffect, useState } from "react";
// import { DashHeader } from "./DashHeader";
import { useNavigate } from "react-router-dom";
import Footer from "../landingPage/Footer";
import { useSelector, useDispatch } from "react-redux";
import { updateUserByToken } from "../../services/api";
import { login } from "../../reduxToolkit/slice/auth";
import Header from "../landingPage/Header";
import { Loader } from "../Loader";

export const NotificationsSettings = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const user = useSelector((state) => state.user);

  const [updateDate, setUpdateDate] = useState({
    emailNotification: false,
  });

  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }

    if (user && user.user) {
      setUpdateDate({
        emailNotification: user.user.emailNotification,
      });
    }
  }, [navigate, user]);

  const handleChange = async (e) => {
    setLoading(true);
    const { name, checked } = e.target;
    const updatedData = {
      ...updateDate,
      [name]: checked,
    };

    setUpdateDate(updatedData);

    console.log("updatedData : ye data bhej rha hu : ", updatedData);

    // Update the user settings via API
    try {
      let output = await updateUserByToken(updatedData);
      console.log("Update user output from my profile: ", output);

      // Dispatch the updated settings to Redux
      dispatch(login({ ...user.user, ...updatedData }));
    } catch (error) {
      console.log("Error updating user settings : ", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="dashboard-bg">
        <Header />

        <div className="container">
          <div
            className="dashboard-content-box notifications-content-bg"
            style={{
              minHeight: "400px",
            }}
          >
            <h2>Notification Setting</h2>
            <div className="notification-setting-box">
              <ul>
                <li>
                  <div className="notification-setting-left">
                    <h3>Email notification</h3>
                    <p>A notification sent to your inbox</p>
                  </div>
                  <div className="form-check form-switch">
                    <input
                      className="form-check-input"
                      name="emailNotification"
                      type="checkbox"
                      role="switch"
                      id="emailNotificationSwitch"
                      checked={updateDate.emailNotification}
                      onChange={handleChange}
                    />
                    <label
                      className="form-check-label"
                      htmlFor="emailNotificationSwitch"
                    ></label>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
