import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  generateKycLink,
  getUserProfileByToken,
  updateUserByToken,
} from "../../services/api";
import { alertMsg, formatDateToInputValue, maxDate } from "../../services/help";

import Footer from "../landingPage/Footer";
import Header from "../landingPage/Header";
import { Loader } from "../Loader";

export const MyProfile = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const [initialUser, setInitialUser] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    dob: "",
    verify: false,
  });

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    dob: "",
    verify: false,
  });

  async function fetchUser() {
    try {
      const userData = await getUserProfileByToken();
      setInitialUser(userData);
      setUser(userData);

      const token = localStorage.getItem("accessToken");
      if (!token) {
        navigate("/");
      }
    } catch (error) {
      console.error("Failed to fetch user profile: ", error);
      navigate("/"); // Optionally redirect to login if there's an error fetching the user
    }
  }

  useEffect(() => {
    fetchUser();
  }, [navigate]);

  const validateInput = (name, value) => {
    let error = "";
    switch (name) {
      case "firstname":
      case "lastname":
        if (!value) {
          error = "This field is required.";
        } else if (value.length > 30) {
          error = "This field should not exceed 30 characters.";
        }
        break;
      case "email":
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!value) {
          error = "Email is required.";
        } else if (!emailRegex.test(value)) {
          error = "Invalid email address.";
        }
        break;
      case "dob":
        const selectedDate = new Date(value);
        const maxAllowedDate = new Date(maxDate());
        if (selectedDate > maxAllowedDate) {
          error = "You must be at least 15 years old.";
        }
        break;
      default:
        break;
    }
    setErrors((prevErrors) => ({ ...prevErrors, [name]: error }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    console.log("name, value : ", name, value);
    setUser((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    validateInput(name, value);
  };

  const isFormValid = () => {
    return (
      Object.values(errors).every((error) => error === "") &&
      Object.values(user).every((value) => value !== "")
    );
  };

  const handleUpdate = async () => {
    if (!isFormValid()) {
      alertMsg("error", "Please fix the errors before submitting");
      return;
    }
    if (JSON.stringify(initialUser) === JSON.stringify(user)) {
      alertMsg("error", "No changes were made");
      console.log("No changes detected, not updating.");
      return;
    }
    setLoading(true);
    let output = await updateUserByToken(user);
    await fetchUser();
    setLoading(false);
    console.log("Update user output from my profile: ", output);
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="dashboard-bg">
        <Header />

        <div className="container">
          <div className="dashboard-content-box">
            {!user?.verify ? (
              <section className="account-update-section">
                <figure>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/account-img.png"
                    }
                    alt="img"
                  />
                </figure>
                <figcaption>
                  <h2>Your account not activated</h2>
                  <p>
                    We hope this message finds you well. We are writing to
                    inform you that it is time to update your account
                    information to ensure continued access to our services.
                  </p>
                </figcaption>
              </section>
            ) : null}

            <section className="my-profile-form">
              <h3>My profile</h3>
              <div className="row">
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Username</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-user"></i>
                      </div>
                      <input
                        type="text"
                        name="firstname"
                        className="form-control"
                        placeholder="username"
                        maxLength={30}
                        value={user.username}
                        disabled
                      />
                      {errors.firstname && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errors.firstname}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>First Name</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-user"></i>
                      </div>
                      <input
                        type="text"
                        name="firstname"
                        className="form-control"
                        placeholder="First Name"
                        maxLength={30}
                        value={user.firstname}
                        onChange={handleChange}
                      />
                      {errors.firstname && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errors.firstname}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Last Name</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-user"></i>
                      </div>
                      <input
                        type="text"
                        name="lastname"
                        className="form-control"
                        placeholder="Last Name"
                        maxLength={30}
                        value={user.lastname}
                        onChange={handleChange}
                      />
                      {errors.lastname && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errors.lastname}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Email address</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-mail"></i>
                      </div>
                      <input
                        type="text"
                        name="email"
                        className="form-control"
                        placeholder="Email address"
                        value={user.email}
                        maxLength={50}
                        onChange={handleChange}
                        disabled={true}
                      />
                      {errors.email && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errors.email}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    <label>Date of birth</label>
                    <div
                      className="input-group"
                      style={{ position: "relative", display: "inline-block" }}
                    >
                      <div className="input-group-icon">
                        <i className="ti ti-cake"></i>
                      </div>
                      <input
                        type="date"
                        name="dob"
                        className="form-control"
                        placeholder="Enter email address"
                        value={formatDateToInputValue(user.dob)}
                        onChange={handleChange}
                        max={maxDate()}
                        style={{
                          borderRadius: "5px",
                          border: "1px solid #ccc",
                          width: "100%",
                        }}
                      />
                      {errors.dob && (
                        <div className="error-message" style={{ color: "red" }}>
                          {errors.dob}
                        </div>
                      )}
                      <style>
                        {`
          input[type="date"]::-webkit-calendar-picker-indicator {
            filter: invert(100%);
          }
          input[type="date"]::-webkit-calendar-picker-indicator:hover {
            filter: invert(100%);
          }
        `}
                      </style>
                    </div>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <div className="update-profile-btn">
                    <button
                      className="btn"
                      onClick={handleUpdate}
                      disabled={!isFormValid()}
                    >
                      Update profile
                    </button>
                  </div>

                  {user?.verify ? null : (
                    <div
                      className="update-profile-btn"
                      onClick={async () => {
                        console.log("KYC Link : ");
                        const data = {
                          levelName: "basic-kyc-level",
                          ttlInSecs: 800,
                          externalUserId: initialUser?._id,
                          lang: "en",
                        };
                        const response = await generateKycLink(data);
                        console.log("KKK Link : ", response);
                        if (response?.kyc?.url) {
                          window.open(
                            response?.kyc?.url,
                            "_blank",
                            "noopener,noreferrer"
                          );
                        }
                      }}
                    >
                      <a className="btn">Verify Your Account</a>
                    </div>
                  )}
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
