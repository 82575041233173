import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useForm, useWatch } from "react-hook-form";
import { resetPasswordByToken } from "../services/api";
import { alertMsg } from "../services/help";
import { Loader } from "../components/Loader";

export const ResetPassword = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const user = location.state;
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();

  const [resetPasswordExpiresAt] = useState(user.resetPasswordExpiresAt);
  const [timeLeft, setTimeLeft] = useState({});

  const calculateTimeLeft = () => {
    const now = new Date().getTime();
    const difference = resetPasswordExpiresAt - now;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60),
      };
    } else {
      timeLeft = { hours: 0, minutes: 0, seconds: 0 };
    }

    return timeLeft;
  };

  useEffect(() => {
    setTimeLeft(calculateTimeLeft());

    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(timer);
  }, [resetPasswordExpiresAt]);

  const onSubmit = async (data) => {
    setLoading(true);
    if (data.password !== data.confirmPassword) {
      alertMsg("error", "Password and confirm password do not match");
      setLoading(false);
      return;
    }

    const output = await resetPasswordByToken(
      user.otp,
      data.password,
      user.resetPasswordToken
    );
    console.log("output from reset password screen: ", output);

    if (output?.user) {
      console.log(output?.user);
      navigate("/login");
    } else {
      console.log(output.message);
    }
    setLoading(false);
  };

  const password = useWatch({
    control,
    name: "password",
  });

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="login-wraper-in" style={{ height: "100vh" }}>
        <div className="login-left">
          <figure>
            <img src="assets/images/logo-coin.png" alt="Logo" />
          </figure>
          <figcaption>
            <h4>Empower users to buy crypto</h4>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.
            </p>
          </figcaption>
        </div>
        <div className="login-right">
          <div className="login-right-in">
            <div className="login-head">
              <a
                href="#"
                onClick={() => navigate("/")}
                style={{
                  cursor: "pointer",
                }}
              >
                <img src="assets/images/logo-white.svg" alt="Logo" />
              </a>
              <div className="login-head-text">
                <h4>Create New Password</h4>
                <p>
                  Your new password must be different from previously used
                  passwords.
                </p>
                <p style={{ color: "red" }}>
                  Time left: {timeLeft.hours}h {timeLeft.minutes}m{" "}
                  {timeLeft.seconds}s
                </p>
              </div>
            </div>
            <div className="login-body">
              <div className="form-box">
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="form-group form-gry">
                    <label>New Password</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-lock"></i>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter password"
                        {...register("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message:
                              "Password must be at least 8 characters long",
                          },
                          validate: {
                            capitalLetter: (value) =>
                              /[A-Z]/.test(value) ||
                              "Password must include at least one capital letter",
                            smallLetter: (value) =>
                              /[a-z]/.test(value) ||
                              "Password must include at least one small letter",
                            hasNumber: (value) =>
                              /\d/.test(value) ||
                              "Password must include at least one number",
                            hasSpecialChar: (value) =>
                              /[@$!%*?&]/.test(value) ||
                              "Password must include at least one special character",
                            noSpaces: (value) =>
                              /^\S*$/.test(value) ||
                              "Password must not contain spaces",
                          },
                        })}
                      />
                    </div>
                    {errors.password && (
                      <small className="text-danger">
                        {errors.password.message}
                      </small>
                    )}
                  </div>
                  <div className="form-group form-gry">
                    <label>Confirm Password</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-lock"></i>
                      </div>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Enter confirm password"
                        {...register("confirmPassword", {
                          required: "Confirm password is required",
                          validate: (value) =>
                            value === password || "Passwords do not match",
                        })}
                      />
                    </div>
                    {errors.confirmPassword && (
                      <small className="text-danger">
                        {errors.confirmPassword.message}
                      </small>
                    )}
                  </div>
                  <div className="form-btn">
                    <button className="btn" type="submit">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
