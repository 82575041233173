import React, { useEffect, useState } from "react";
import {
  checkApproval,
  checkApprovalForStaking,
  getStakePlans,
  giveApprovalToStaking,
  stakeToken,
} from "../../../contract/contractHelper";
import { useWeb3ModalAccount } from "@web3modal/ethers5/react";
import { alertMsg, secondToMonth } from "../../../services/help";
import { ethers } from "ethers";
import { createStakeToken } from "../../../services/api";

export const StakeCards = ({ cuccBalance, setLoading }) => {
  const [inputAmount, setInputAmount] = useState("");
  const [selectedPlan, setSelectedPlan] = useState("");
  const [stakePlans, setStakePlans] = useState([]);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const [userApproval, setUserApproval] = useState(0);

  const handleStakeToken = async () => {
    console.log(
      "Amount and plan id : ",
      selectedPlan.planId,
      inputAmount,
      selectedPlan.duration,
      cuccBalance,
      typeof cuccBalance
    );
    if (Number(cuccBalance) < Number(inputAmount)) {
      alertMsg("error", "Insufficient CUCC Token Balance");
      return;
    }
    if (!Number(inputAmount) > 0) {
      alertMsg("error", "Invalid input amount");
      return;
    }

    if (!Number(selectedPlan.duration) > 0) {
      alertMsg("error", "Invalid selected stake plan");
      return;
    }

    if (window.ethereum) {
      setLoading(true);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const accounts = await provider.listAccounts();

      try {
        if (parseFloat(userApproval) < parseFloat(inputAmount)) {
          await giveApprovalToStaking(signer);
          alertMsg("success", "Approval has been successfully");
        }

        const inputAmountInWei = ethers.utils.parseUnits(
          inputAmount.toString(),
          18
        ); // Assuming USDT has 6 decimals

        const transaction = await stakeToken(
          selectedPlan.planId,
          inputAmountInWei,
          signer
        );
        if (transaction?.transaction?.hash) {
          console.log(
            "stake transaction details from blockchain : ",
            transaction
          );
          await createStakeToken({
            wallet: accounts[0],
            durations: `${secondToMonth(selectedPlan.duration)} Months`,
            token: inputAmount,
            transactionHash: transaction?.transaction?.hash,
            stakeId: Number(transaction?.event?.stakeId),
          });
          alertMsg("success", "Token staked successfully");
        } else {
          alertMsg("error", transaction);
        }
        console.log(transaction);
        setLoading(false);
      } catch (error) {
        console.error("Transaction failed:", error);
        alertMsg("error", "Purchase failed.");
      }
      setInputAmount("");
    } else {
      console.log("metamask not available");
    }
  };

  const fetchData2 = async (userAddress) => {
    try {
      const sPlan = await getStakePlans();
      const planIds = sPlan[0];
      const durations = sPlan[1];
      const aprs = sPlan[2];

      const plans = planIds.map((id, index) => ({
        planId: planIds[index].toString(), // Convert BigNumber to string
        duration: durations[index].toString(), // Convert BigNumber to string
        apr: aprs[index].toString(), // Convert BigNumber to string
      }));

      setStakePlans(plans);

      let userApprovalAmount = await checkApprovalForStaking(userAddress);
      setUserApproval(userApprovalAmount);
    } catch (error) {
      console.log("Error fetching staking plans:", error);
    }
  };

  useEffect(() => {
    try {
      fetchData2(address);
    } catch (err) {
      console.log(err);
    }
  }, [isConnected, address]);

  console.log("stakePlans : ", stakePlans);
  console.log("Selected staking plans : ", selectedPlan);
  console.log("setUserApproval : ", userApproval);

  return (
    <>
      <div
        className="tab-pane fade"
        id="pills-profile"
        role="tabpanel"
        aria-labelledby="pills-profile-tab"
      >
        <div className="buy-content-list">
          <ul className="buy-content-ul">
            <li>
              <div className="buy-des-left">
                <h5>Amount</h5>
                <input
                  type="number"
                  name="inputAmount"
                  min={0}
                  className="form-control"
                  placeholder="Input Amount"
                  value={inputAmount}
                  onChange={(e) => {
                    const value = e.target.value;
                    if (value >= 0) {
                      setInputAmount(value);
                    }
                  }}
                />
              </div>
              <div
                style={{
                  display: "flex",
                  //   justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <h5>CUCC</h5>
              </div>
            </li>

            <li>
              <div className="buy-des-right">
                <div className="dropdown">
                  <a
                    className="dropdown-toggle"
                    href="#"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {!selectedPlan
                      ? "Select a Plan"
                      : secondToMonth(selectedPlan.duration) +
                        " Months " +
                        selectedPlan.apr / 100 +
                        "% APR"}
                  </a>
                  <ul className="dropdown-menu">
                    {stakePlans?.map((item, index) => {
                      if (item.duration !== 0) {
                        return (
                          <li key={index}>
                            <a
                              className="dropdown-item"
                              href="#"
                              onClick={() => setSelectedPlan(item)}
                            >
                              {secondToMonth(item.duration)} Months (
                              {item.apr / 100}% APR)
                            </a>
                          </li>
                        );
                      } else {
                        return null; // Skip rendering this item
                      }
                    })}
                  </ul>
                </div>
              </div>
            </li>
          </ul>
        </div>

        <br />
        <div className="buy-btn" onClick={handleStakeToken}>
          <a className="btn">Stake CUCC</a>
        </div>
      </div>
    </>
  );
};
