import { configureStore } from "@reduxjs/toolkit";
import auth from "../slice/auth";
import user from "../slice/auth";
import { combineReducers } from "redux";
import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
// import garmentShorting from "../slice/garmentShorting";

const rootReducer = combineReducers({
  // auth: auth,
  user,
  // garmentShorting: garmentShorting,
});
const persistConfig = {
  key: "root",
  version: 1,
  storage: storage,
  whitelist: ["user"],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
});
