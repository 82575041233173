import React from "react";

const Team = () => {
  return (
    <section className="team-sec section-padding">
      <div className="container">
        <div className="heading-text text-center">
          <h4>Meet the team</h4>
        </div>
        <div className="row">
          <div className="col-md-3">
            <article className="team-box">
              <figure>
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/team01.png"}
                  alt="Team Member"
                />
              </figure>
              <figcaption>
                <h3>Tanya Johnson</h3>
                <p>Business head</p>
              </figcaption>
            </article>
          </div>
          <div className="col-md-3">
            <article className="team-box">
              <figure>
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/team02.png"}
                  alt="Team Member"
                />
              </figure>
              <figcaption>
                <h3>Walter Smith</h3>
                <p>Blockchain developer</p>
              </figcaption>
            </article>
          </div>
          <div className="col-md-3">
            <article className="team-box">
              <figure>
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/team03.png"}
                  alt="Team Member"
                />
              </figure>
              <figcaption>
                <h3>Malissa Hite</h3>
                <p>UI designer</p>
              </figcaption>
            </article>
          </div>
          <div className="col-md-3">
            <article className="team-box">
              <figure>
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/team04.png"}
                  alt="Team Member"
                />
              </figure>
              <figcaption>
                <h3>LissaWatson</h3>
                <p>Company CEO</p>
              </figcaption>
            </article>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Team;
