import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../landingPage/Footer";
import { getAllBuyTx, getAllStakeTx, updateStake } from "../../services/api";
import { alertMsg, formatDate, trimAddress } from "../../services/help";
import { TransactionModal } from "../Modal/TransactionModal";
import { Loader } from "../Loader";
import Header from "../landingPage/Header";
import { unstakeTokens } from "../../contract/contractHelper";
import { ethers } from "ethers";

export const Staking = () => {
  const navigate = useNavigate();
  const [buyTx, setBuyTx] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [buyMethod, setBuyMethod] = useState("");
  const [limit, setLimit] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [tx, setTx] = useState();
  const [loading, setLoading] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  const handleUnstakeToken = async (id, stakeId) => {
    if (window.ethereum) {
      setLoading(true);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();
      const accounts = await provider.listAccounts();

      try {
        const transaction = await unstakeTokens(stakeId, signer);
        if (transaction?.hash) {
          alertMsg("success", "Token Unstaked successfully");
          await updateStake({ id: id });
        } else {
          alertMsg("error", transaction);
        }
        console.log(transaction);
        setLoading(false);
      } catch (error) {
        console.error("Transaction failed:", error);
        alertMsg("error", "Purchase failed.");
        setLoading(false);
      }
    } else {
      console.log("Metamask not available");
    }
  };

  async function fetchTx(page) {
    const response = await getAllStakeTx({
      page,
      limit,
    });
    console.log("stake tx getAllStakeTx : ", response);
    setBuyTx(response);
  }

  useEffect(() => {
    fetchTx(currentPage);
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
  }, [currentPage, navigate, loading]);

  console.log("stake Tx all page : ", buyTx);

  if (!buyTx) {
    return <Loader />;
  }

  const totalPages = buyTx.stake.totalPages;

  // Filter transactions based on search term, buy method, from date, and to date
  const filteredTransactions = buyTx.stake.docs.filter((transaction) => {
    const matchesSearchTerm =
      transaction._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      transaction.wallet.toLowerCase().includes(searchTerm.toLowerCase()) ||
      transaction.token
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      formatDate(transaction.createdAt)
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      transaction.durations.toLowerCase().includes(searchTerm.toLowerCase());

    // Adjust date filtering
    const transactionDate = new Date(transaction.createdAt);
    const fromDateTime = fromDate
      ? new Date(new Date(fromDate).setHours(0, 0, 0, 0))
      : null;
    const toDateTime = toDate
      ? new Date(new Date(toDate).setHours(23, 59, 59, 999))
      : null;

    const matchesDateRange =
      (!fromDateTime || transactionDate >= fromDateTime) &&
      (!toDateTime || transactionDate <= toDateTime);

    const isDateFilterApplied = fromDate || toDate;
    return matchesSearchTerm && (isDateFilterApplied ? matchesDateRange : true);
  });

  if (loading) {
    return <Loader />;
  }

  return (
    <>
      <div className="dashboard-bg">
        <Header />
        <div className="wraper-in">
          <div className="container">
            <div className="dashboard-content-box">
              <div className="transactions-head">
                <h3>Staking</h3>
                <div
                  className="transactions-drop-down"
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <div
                    className="date-filter"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginRight: "20px",
                    }}
                  >
                    <label style={{ marginRight: "5px" }}>From</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="From date"
                      onChange={(e) => setFromDate(e.target.value)}
                      style={{ width: "auto" }}
                    />
                    <label style={{ marginRight: "5px" }}>To</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="To date"
                      onChange={(e) => setToDate(e.target.value)}
                      style={{ width: "auto" }}
                    />
                  </div>
                  <input
                    type="text"
                    maxLength={50}
                    className="form-control"
                    placeholder="Search by All"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ width: "250px" }}
                  />
                </div>
              </div>

              <div className="transactions-table-bg">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Transaction ID</th>
                      <th>Amount</th>
                      <th>Date</th>
                      <th>Durations</th>
                      <th>Status</th>
                      <th>Destination address</th>
                      <th>Unstake</th>
                      <th>Stake</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTransactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>#{index + 1 + (currentPage - 1) * limit}</td>
                        <td>{transaction._id}</td>
                        <td>{transaction.token} CUCC</td>
                        <td>{formatDate(transaction.createdAt)}</td>
                        <td>{transaction.durations}</td>
                        <td>
                          <button
                            className={`status-tag ${
                              transaction.status === "unclaimed"
                                ? "failed-bg"
                                : ""
                            }`}
                          >
                            {transaction.status.toUpperCase()}
                          </button>
                        </td>
                        <td>{trimAddress(transaction.wallet)}</td>
                        <td>
                          <button
                            className="unstake-tag"
                            disabled={transaction.status === "claimed"}
                            onClick={async () => {
                              console.log(
                                "selected transaction for unstake : " +
                                  JSON.stringify(transaction)
                              );
                              await handleUnstakeToken(
                                transaction?._id,
                                transaction?.stakeId ?? 0
                              );
                            }}
                          >
                            {transaction.status === "claimed"
                              ? "Unstaked"
                              : "Unstake"}
                          </button>
                        </td>
                        <td>
                          <a
                            href="#"
                            className="viw-btn btn"
                            onClick={(e) => {
                              e.preventDefault();
                              setTx(transaction);
                              toggle();
                            }}
                          >
                            <i className="ti ti-eye"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {totalPages > 1 && (
                <nav aria-label="Page navigation">
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <i className="ti ti-chevron-left"></i>
                      </button>
                    </li>
                    {[...Array(totalPages).keys()].map((pageNumber) => (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          pageNumber + 1 === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber + 1)}
                        >
                          {pageNumber + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <i className="ti ti-chevron-right"></i>
                      </button>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />

      {/* Transaction Modal  */}
      <TransactionModal
        tx={tx}
        isOpen={isOpen}
        toggle={toggle}
        type={"stake"}
      />
    </>
  );
};
