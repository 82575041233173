import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  getCopperLivePrice,
  getUserNotificationsCount,
  getUserProfileByToken,
} from "../../services/api";
import { useDispatch } from "react-redux";
import { logout } from "../../reduxToolkit/slice/auth";

const Header = () => {
  const location = useLocation();
  const navigate = useNavigate();
  // const [user, setUser] = useState("");
  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    dob: "",
    verify: false,
  });
  const [copperPrice, setCopperPrice] = useState(0);
  const [notification, setNotification] = useState();
  const dispatch = useDispatch();

  const handleLogin = () => {
    navigate("/login");
  };

  const handleSignup = () => {
    navigate("/signup");
  };

  const handleLogout = () => {
    localStorage.removeItem("accessToken");
    dispatch(logout());
    navigate("/");
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        const token = await getUserProfileByToken();
        if (token) {
          setUser(token);
          setNotification(await getUserNotificationsCount());
        } else {
          localStorage.removeItem("accessToken");
          navigate("/login");
        }
      } catch (error) {
        console.error("Failed to fetch user profile: ", error);
      }
    }

    fetchUser();
  }, [dispatch]);

  // useEffect(async () => {
  //   setCopperPrice(await getCopperLivePrice());
  // }, []);

  useEffect(() => {
    const fetchCopperPrice = async () => {
      try {
        const price = await getCopperLivePrice();
        setCopperPrice(price);
      } catch (e) {
        console.log("Fetching price failed : ", e);
      }
    };

    fetchCopperPrice(); // Initial fetch

    const interval = setInterval(() => {
      fetchCopperPrice();
    }, 10000); // Fetch every 10 seconds

    return () => clearInterval(interval); // Clean up interval on component unmount
  }, []);

  const getActiveClass = (path) => (location.pathname === path ? "active" : "");

  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg bg-dark">
          <div className="container" id="home-section">
            <a className="navbar-brand" onClick={() => navigate("/")}>
              <img
                src={process.env.PUBLIC_URL + "/assets/images/logo-white.svg"}
                alt="Logo"
                width={250}
                height={50}
              />
            </a>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    aria-current="page"
                    href="/#home-section"
                  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#purpose-section">
                    Purpose
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#faq-section">
                    FAQ
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/#inquiry">
                    Contact us
                  </a>
                </li>
              </ul>
              <div className="d-flex gap-4">
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    color: "red",
                  }}
                >
                  Copper Live Price : {copperPrice?.data?.price}
                </div>
                {user?.username ? (
                  <>
                    <button
                      className="notification-btn"
                      onClick={(e) => {
                        e.preventDefault();
                        navigate("/dashboard/notifications");
                      }}
                      style={{
                        width: "42px",
                        height: "42px",
                        background: "#2e2e2e",
                        borderRadius: "4px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        fontSize: "28px",
                        border: "none",
                        color: "#fab267",
                        position: "relative",
                        cursor: "pointer",
                        padding: "10px",
                      }}
                    >
                      <i className="ti ti-bell"></i>
                      {notification?.unread > 0 && (
                        <span
                          style={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            backgroundColor: "red",
                            color: "white",
                            borderRadius: "100%",
                            padding: "5px 10px",
                            fontSize: "12px",
                            transform: "translate(50%, -50%)",
                          }}
                        >
                          {notification?.unread > 9 ? (
                            <span>
                              9<sup>+</sup>
                            </span>
                          ) : (
                            notification?.unread
                          )}
                        </span>
                      )}
                    </button>
                    <div className="dropdown user-profile-drop">
                      <button
                        className="btn btn-secondary dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/user.png"
                            }
                            alt="user"
                          />
                        </i>
                        <span>{user?.username}</span>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={() => {
                              navigate("/dashboard");
                            }}
                          >
                            Dashboard
                          </a>
                        </li>

                        <li>
                          <a
                            className="dropdown-item"
                            onClick={handleLogout}
                            href="/"
                          >
                            Logout
                          </a>
                        </li>
                      </ul>
                    </div>{" "}
                  </>
                ) : (
                  <>
                    <button className="btn btn-primary" onClick={handleLogin}>
                      Login
                    </button>
                    <button className="btn btn-outline" onClick={handleSignup}>
                      Signup
                    </button>{" "}
                  </>
                )}
              </div>
            </div>
          </div>
        </nav>
      </header>

      {location.pathname !== "/" ? (
        <div className="wraper-in">
          <div className="dashboard-tab">
            <div className="container">
              <ul>
                <li className={getActiveClass("/dashboard")}>
                  <a
                    href="#!"
                    onClick={() => {
                      navigate("/dashboard");
                    }}
                  >
                    Dashboard
                  </a>
                </li>
                <li className={getActiveClass("/dashboard/my-profile")}>
                  <a
                    href="#!"
                    onClick={() => {
                      navigate("/dashboard/my-profile");
                    }}
                  >
                    My profile
                  </a>
                </li>
                <li className={getActiveClass("/dashboard/change-password")}>
                  <a
                    href="#!"
                    onClick={() => {
                      navigate("/dashboard/change-password");
                    }}
                  >
                    Change Password
                  </a>
                </li>
                {/* For Notification settings only */}
                <li
                  className={getActiveClass(
                    "/dashboard/notifications-settings"
                  )}
                >
                  <a
                    href="#!"
                    onClick={() => {
                      navigate("/dashboard/notifications-settings");
                    }}
                  >
                    Notification Setting
                  </a>
                </li>
                <li className={getActiveClass("/dashboard/transactions")}>
                  <a
                    href="#!"
                    onClick={() => {
                      navigate("/dashboard/transactions");
                    }}
                  >
                    Transactions
                  </a>
                </li>
                <li className={getActiveClass("/dashboard/staking")}>
                  <a
                    href="#!"
                    onClick={() => {
                      navigate("/dashboard/staking");
                    }}
                  >
                    Staking
                  </a>
                </li>
                <li>
                  <a href="#!" onClick={handleLogout}>
                    Logout
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default Header;
