import React, { useEffect, useState } from "react";
// import { DashHeader } from "./DashHeader";
import { useNavigate } from "react-router-dom";
import Footer from "../landingPage/Footer";
import { getAllBuyTx } from "../../services/api";
import { formatDate, trimAddress } from "../../services/help";
import { TransactionModal } from "../Modal/TransactionModal";
import { Loader } from "../Loader";
import Header from "../landingPage/Header";

export const Transactions = () => {
  const navigate = useNavigate();
  const [buyTx, setBuyTx] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [buyMethod, setBuyMethod] = useState("");
  const [limit, setLimit] = useState(10);
  const [isOpen, setIsOpen] = useState(false);
  const [tx, setTx] = useState();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  async function fetchTx(page) {
    const response = await getAllBuyTx({
      page,
      limit,
    });
    console.log("buyTx getAllBuyTx : ", response);
    setBuyTx(response);
  }

  useEffect(() => {
    fetchTx(currentPage);
    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
  }, [currentPage, navigate]);

  console.log("buyTx all page : ", buyTx);

  if (!buyTx) {
    return <Loader />;
  }

  const totalPages = buyTx.buy.totalPages;

  // Filter transactions based on search term, buy method, from date, and to date
  const filteredTransactions = buyTx.buy.docs.filter((transaction) => {
    const matchesSearchTerm =
      transaction._id.toLowerCase().includes(searchTerm.toLowerCase()) ||
      transaction.depositWallet
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      transaction.amount
        .toString()
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      formatDate(transaction.createdAt)
        .toLowerCase()
        .includes(searchTerm.toLowerCase()) ||
      transaction.buyMethod.toLowerCase().includes(searchTerm.toLowerCase());
    const matchesBuyMethod = buyMethod
      ? transaction.buyMethod === buyMethod
      : true;

    // Adjust date filtering
    const transactionDate = new Date(transaction.createdAt);
    const fromDateTime = fromDate
      ? new Date(new Date(fromDate).setHours(0, 0, 0, 0))
      : null;
    const toDateTime = toDate
      ? new Date(new Date(toDate).setHours(23, 59, 59, 999))
      : null;

    const matchesDateRange =
      (!fromDateTime || transactionDate >= fromDateTime) &&
      (!toDateTime || transactionDate <= toDateTime);

    const isDateFilterApplied = fromDate || toDate;
    return (
      matchesSearchTerm &&
      matchesBuyMethod &&
      (isDateFilterApplied ? matchesDateRange : true)
    );
  });

  return (
    <>
      <div className="dashboard-bg">
        <Header />
        <div className="wraper-in">
          <div className="container">
            <div className="dashboard-content-box">
              <div className="transactions-head">
                <h3>Transactions</h3>
                <div
                  className="transactions-drop-down"
                  style={{ display: "flex", alignItems: "center", gap: "20px" }}
                >
                  <div
                    className="date-filter"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                      marginRight: "20px",
                    }}
                  >
                    <label style={{ marginRight: "5px" }}>From</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="From date"
                      onChange={(e) => setFromDate(e.target.value)}
                      style={{ width: "auto" }}
                    />
                    <label style={{ marginRight: "5px" }}>To</label>
                    <input
                      type="date"
                      className="form-control"
                      placeholder="To date"
                      onChange={(e) => setToDate(e.target.value)}
                      style={{ width: "auto" }}
                    />
                  </div>
                  <input
                    type="text"
                    maxLength={50}
                    className="form-control"
                    placeholder="Search by All"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    style={{ width: "250px" }}
                  />
                  <select
                    className="form-control"
                    style={{ width: "150px" }}
                    onChange={(e) => setBuyMethod(e.target.value)}
                  >
                    <option value="">All</option>
                    <option value="ETH">ETH</option>
                    <option value="WBTC">WBTC</option>
                    <option value="USDT">USDT</option>
                  </select>
                </div>
              </div>

              <div className="transactions-table-bg">
                <table className="table">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Transaction ID</th>
                      <th>Amount</th>
                      <th>Date</th>
                      <th>Tokens</th>
                      <th>Status</th>
                      <th>Destination address</th>
                      <th>View</th>
                    </tr>
                  </thead>
                  <tbody>
                    {filteredTransactions.map((transaction, index) => (
                      <tr key={index}>
                        <td>#{index + 1 + (currentPage - 1) * limit}</td>
                        <td>{transaction._id}</td>
                        <td>
                          {transaction.amount} {transaction.buyMethod}
                        </td>
                        <td>{formatDate(transaction.createdAt)}</td>
                        <td>{transaction.token.toFixed(2)} CUCC</td>
                        <td>
                          <button
                            className={`status-tag ${
                              transaction.status === "pending"
                                ? "pending-bg"
                                : ""
                            }`}
                          >
                            {transaction.status}
                          </button>
                        </td>
                        <td>{trimAddress(transaction.depositWallet)}</td>
                        <td>
                          <a
                            href="#"
                            className="viw-btn btn"
                            onClick={(e) => {
                              e.preventDefault();
                              setTx(transaction);
                              toggle();
                            }}
                          >
                            <i className="ti ti-eye"></i>
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              {totalPages > 1 && (
                <nav aria-label="Page navigation">
                  <ul className="pagination">
                    <li
                      className={`page-item ${
                        currentPage === 1 ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(currentPage - 1)}
                        disabled={currentPage === 1}
                      >
                        <i className="ti ti-chevron-left"></i>
                      </button>
                    </li>
                    {[...Array(totalPages).keys()].map((pageNumber) => (
                      <li
                        key={pageNumber}
                        className={`page-item ${
                          pageNumber + 1 === currentPage ? "active" : ""
                        }`}
                      >
                        <button
                          className="page-link"
                          onClick={() => setCurrentPage(pageNumber + 1)}
                        >
                          {pageNumber + 1}
                        </button>
                      </li>
                    ))}
                    <li
                      className={`page-item ${
                        currentPage === totalPages ? "disabled" : ""
                      }`}
                    >
                      <button
                        className="page-link"
                        onClick={() => setCurrentPage(currentPage + 1)}
                        disabled={currentPage === totalPages}
                      >
                        <i className="ti ti-chevron-right"></i>
                      </button>
                    </li>
                  </ul>
                </nav>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />

      {/* Transaction Modal  */}
      <TransactionModal tx={tx} isOpen={isOpen} toggle={toggle} type={"buy"} />
    </>
  );
};
