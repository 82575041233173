import React, { useEffect, useState } from "react";
import { alertMsg, getCoinPrice } from "../../services/help";
import { useNavigate } from "react-router-dom";
import Footer from "../landingPage/Footer";
import {
  useWeb3ModalProvider,
  useWeb3ModalAccount,
} from "@web3modal/ethers5/react";
import { ethers } from "ethers";

import {
  CopperAddress,
  TokenPrice,
  USDTAddress,
  WBTCAddress,
} from "../../contract/contract";

import {
  buyWithETH,
  buyWithUSDT,
  buyWithWBTC,
  checkApproval,
  getBalanceOf,
  getEthBtcPrice,
  setApproval,
} from "../../contract/contractHelper";
import { createBuyToken, generatePaymentLink } from "../../services/api";
import { Loader } from "../Loader";
import Header from "../landingPage/Header";
import { StakeCards } from "./Cards/StakeCards";

export const Dashboard = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [balance, setBalance] = useState(0);
  const { address, chainId, isConnected } = useWeb3ModalAccount();
  const { walletProvider } = useWeb3ModalProvider();
  const [inputAmount, setInputAmount] = useState("");
  const [outputAmount, setOutputAmount] = useState("");
  const [inputToken, setInputToken] = useState("ETH");
  const [depositWallet, setDepositWallet] = useState("");
  const [isValid, setIsValid] = useState(false);

  const [ethBtcPrice, setEthBtcPrice] = useState({
    eth: 0,
    btc: 0,
  });
  // const [coinPrice, setCoinPrice] = useState(null);
  const [coinPrice, setCoinPrice] = useState({
    bitcoin: { usd: 0, usd_24h_change: 0 },
    ethereum: { usd: 0, usd_24h_change: 0 },
    tether: { usd: 0, usd_24h_change: 0 },
  });
  const [isApproved, setApproved] = useState(false);
  const [userApproval, setUserApproval] = useState({
    usdt: 0,
    wbtc: 0,
  });

  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const token = await getCoinPrice();
        setCoinPrice(token);
        console.log("token", token);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        console.error(err);
      }
    }

    fetchData();
  }, []);

  async function fetchData2() {
    try {
      if (window.ethereum && isConnected) {
        setLoading(true);
        await window.ethereum.request({ method: "eth_requestAccounts" });
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await provider.listAccounts();
        console.log("accounts : is here : ", accounts);
        let balance = await getBalanceOf(accounts[0], CopperAddress);
        setBalance(balance.toFixed(2));
        console.log("balance : is here : ", balance);

        let price = await getEthBtcPrice();
        setEthBtcPrice({
          eth: price[0] / 10 ** 6,
          btc: price[1] / 10 ** 6,
        });

        let userApprovalAmount = await checkApproval(accounts[0]);
        setUserApproval(userApprovalAmount);
        setLoading(false);
      } else {
        setLoading(false);
        console.log("metaask not available");
      }
    } catch (error) {
      console.log("error try catch form Dashboard : ", error);
    }
  }

  useEffect(() => {
    try {
      fetchData2();
    } catch (err) {
      console.log(err);
    }

    const token = localStorage.getItem("accessToken");
    if (!token) {
      navigate("/");
    }
  }, [isConnected, address, isApproved]);

  const handleTokenSelect = (token) => {
    setInputToken(token);
  };

  const rateConvertion = async () => {
    let tokens = 0;

    if (inputToken === "ETH") {
      tokens = (inputAmount * ethBtcPrice.eth) / TokenPrice;
      console.log(`rate conversion in ${inputAmount} ETH : `, tokens);

      // return tokens;
    } else if (inputToken === "WBTC") {
      tokens = (inputAmount * ethBtcPrice.btc) / TokenPrice;
      console.log(`rate conversion in ${inputAmount} WETH : `, tokens);
      // return tokens;
    } else {
      tokens = inputAmount / TokenPrice;
      console.log(`rate conversion in ${inputAmount} USDT: `, tokens);
      // return tokens;
    }
    setOutputAmount(tokens > 0 ? tokens.toFixed(3) : "");
  };

  useEffect(() => {
    rateConvertion();
  }, [inputAmount, inputToken, ethBtcPrice]);

  const handleFiatBuy = async () => {
    if (!isValid) {
      alertMsg("error", "Receiver wallet address is not valid");
      return;
    }
    const response = await generatePaymentLink({
      price: inputAmount,
      depositWallet: depositWallet,
    });
    console.log("KKK Link : ", response);
    if (response?.payment) {
      window.open(response?.payment, "_blank", "noopener,noreferrer");
    }
  };

  const handleBuyToken = async () => {
    if (!isConnected) {
      alertMsg("error", "Please connect to your wallet");
      return;
    }
    if (!inputAmount || inputAmount <= 0) {
      alertMsg("error", "Please enter a valid amount greater than zero");
      return;
    }
    if (inputToken === "USD") {
      await handleFiatBuy();
      return;
    }
    if (window.ethereum) {
      setLoading(true);
      await window.ethereum.request({ method: "eth_requestAccounts" });
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const signer = provider.getSigner();

      try {
        // let balance = await getBalanceOf(address, USDTAddress, signer);

        if (inputToken === "USDT") {
          if (Number(inputAmount) > Number(userApproval?.usdt)) {
            await setApproval(USDTAddress, signer);
            setApproved(!isApproved);
            alertMsg("success", "Approval has been successfully");
          }
          let balance = await getBalanceOf(address, USDTAddress);
          if (inputAmount > balance) {
            alertMsg("error", "Insufficient USDT funds");
            setLoading(false);
            return;
          }
          const transaction = await buyWithUSDT(inputAmount, signer);
          console.log("Transaction successful:", transaction);
          if (transaction?.hash) {
            await createBuyToken({
              depositWallet: address,
              amount: inputAmount,
              token: outputAmount,
              transactionHash: transaction?.hash,
              buyMethod: inputToken,
            });
            alertMsg("success", "Token purchase  successfully");
          } else {
            alertMsg("error", transaction);
          }
        }

        if (inputToken === "WBTC") {
          if (Number(inputAmount) > Number(userApproval?.wbtc)) {
            await setApproval(WBTCAddress, signer);
            alertMsg("success", "Approval has been successfully");
          }
          let balance = await getBalanceOf(address, WBTCAddress);
          if (inputAmount > balance) {
            alertMsg("error", "Insufficient WBTC funds");
            setLoading(false);
            return;
          }

          const transaction = await buyWithWBTC(inputAmount, signer);
          if (transaction?.hash) {
            await createBuyToken({
              depositWallet: address,
              amount: inputAmount,
              token: outputAmount,
              transactionHash: transaction?.hash,
              buyMethod: inputToken,
            });
            alertMsg("success", "Token purchase successfully");
          } else {
            alertMsg("error", transaction);
          }
          console.log(transaction);
          setLoading(false);
        }

        if (inputToken === "ETH") {
          const transaction = await buyWithETH(inputAmount, signer);
          if (transaction?.hash) {
            await createBuyToken({
              depositWallet: address,
              amount: inputAmount,
              token: outputAmount,
              transactionHash: transaction?.hash,
              buyMethod: inputToken,
            });
            alertMsg("success", "Token purchase successfully");
          } else {
            setLoading(false);
            alertMsg("error", transaction);
          }
        }

        await fetchData2();
      } catch (error) {
        console.error("Transaction failed:", error);
        alertMsg("error", "Purchase failed.");
      }
      setInputAmount("");
      setOutputAmount("");
    } else {
      console.log("metamask not available");
    }
    setLoading(false);
  };

  return (
    <>
      {loading ? <Loader /> : null}
      <>
        <div className="dashboard-bg">
          <Header />

          <div className="container">
            <div className="dashboard-content-box">
              <section className="current-balance-box">
                <figure>
                  <img src="assets/images/coin.png" alt="coin" />
                </figure>
                <figcaption>
                  <h5>Current balance</h5>
                  <h2>{balance} CUCC</h2>
                </figcaption>

                <div className="buy-btn">
                  <w3m-button />
                </div>
              </section>
              <section className="coin-info">
                <div className="row">
                  <div className="col-md-4">
                    <article>
                      <div className="coin-sec-head">
                        <figure>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/btc.svg"
                            }
                            alt="BTC"
                          />
                        </figure>
                        <figcaption>
                          <span>BTC</span>
                          <h4>Bitcoin</h4>
                        </figcaption>
                      </div>
                      <div className="coin-sec-body">
                        <div className="coin-left">
                          <h4>{ethBtcPrice?.btc} USD</h4>
                          <div className="value-up-down">
                            {coinPrice?.bitcoin?.usd_24h_change > 0 ? (
                              <div className="updown-icon updown-icon-green">
                                <i className="ti ti-arrow-up"></i>
                              </div>
                            ) : (
                              <div className="updown-icon">
                                <i className="ti ti-arrow-down"></i>
                              </div>
                            )}
                            <span>
                              {coinPrice?.bitcoin?.usd_24h_change
                                .toString()
                                .substring(0, 6)}
                              %{" "}
                            </span>
                          </div>
                        </div>
                        <div className="coin-right">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/chart${
                                coinPrice?.bitcoin?.usd_24h_change > 0
                                  ? "-green"
                                  : ""
                              }.png`
                            }
                            alt="Chart"
                          />
                        </div>
                      </div>
                    </article>
                  </div>
                  {/* Repeat the same structure for other coins */}
                  <div className="col-md-4">
                    <article>
                      <div className="coin-sec-head">
                        <figure>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/usdt.svg"
                            }
                            alt="USDT"
                          />
                        </figure>
                        <figcaption>
                          <span>USDT</span>
                          <h4>Tether</h4>
                        </figcaption>
                      </div>
                      <div className="coin-sec-body">
                        <div className="coin-left">
                          <h4>{coinPrice?.tether?.usd} USD</h4>
                          <div className="value-up-down">
                            {coinPrice?.tether?.usd_24h_change > 0 ? (
                              <div className="updown-icon updown-icon-green">
                                <i className="ti ti-arrow-up"></i>
                              </div>
                            ) : (
                              <div className="updown-icon">
                                <i className="ti ti-arrow-down"></i>
                              </div>
                            )}

                            <span>
                              {coinPrice?.tether?.usd_24h_change
                                .toString()
                                .substring(0, 6)
                                .toString()
                                .substring(0, 6)}
                              %{" "}
                            </span>
                          </div>
                        </div>
                        <div className="coin-right">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/chart${
                                coinPrice?.tether?.usd_24h_change > 0
                                  ? "-green"
                                  : ""
                              }.png`
                            }
                            alt="Chart"
                          />
                        </div>
                      </div>
                    </article>
                  </div>
                  {/* Repeat the same structure for other coins */}
                  <div className="col-md-4">
                    <article>
                      <div className="coin-sec-head">
                        <figure>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/images/eth.svg"
                            }
                            alt="ETH"
                          />
                        </figure>
                        <figcaption>
                          <span>ETH</span>
                          <h4>Ethereum</h4>
                        </figcaption>
                      </div>
                      <div className="coin-sec-body">
                        <div className="coin-left">
                          <h4>{ethBtcPrice?.eth} USD</h4>
                          <div className="value-up-down">
                            {coinPrice?.ethereum?.usd_24h_change > 0 ? (
                              <div className="updown-icon updown-icon-green">
                                <i className="ti ti-arrow-up"></i>
                              </div>
                            ) : (
                              <div className="updown-icon">
                                <i className="ti ti-arrow-down"></i>
                              </div>
                            )}
                            <span>
                              {coinPrice?.ethereum?.usd_24h_change
                                .toString()
                                .substring(0, 6)}{" "}
                              %{" "}
                            </span>
                          </div>
                        </div>
                        <div className="coin-right">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `/assets/images/chart${
                                coinPrice?.ethereum?.usd_24h_change > 0
                                  ? "-green"
                                  : ""
                              }.png`
                            }
                            alt="Chart"
                          />
                        </div>
                      </div>
                    </article>
                  </div>
                </div>
              </section>
              <section className="overview-stake-box">
                <div className="row">
                  <div className="col-md-8">
                    <div className="overview-box">
                      <div className="overview-head">
                        <h3>Overview</h3>
                        {/* <div className="month-dropdown">
                          <select className="form-control">
                            <option>Monthly</option>
                          </select>
                        </div> */}
                      </div>
                      <div className="graph-box">
                        <img src="assets/images/graph.png" alt="graph" />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="buy-stake-box">
                      <div className="buy-stake-tab">
                        <ul
                          className="nav nav-pills"
                          id="pills-tab"
                          role="tablist"
                        >
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link active"
                              id="pills-home-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-home"
                              type="button"
                              role="tab"
                              aria-controls="pills-home"
                              aria-selected="true"
                            >
                              Buy
                            </button>
                          </li>
                          <li className="nav-item" role="presentation">
                            <button
                              className="nav-link"
                              id="pills-profile-tab"
                              data-bs-toggle="pill"
                              data-bs-target="#pills-profile"
                              type="button"
                              role="tab"
                              aria-controls="pills-profile"
                              aria-selected="false"
                            >
                              Stake
                            </button>
                          </li>
                        </ul>
                      </div>
                      <div className="tab-content" id="pills-tabContent">
                        <div
                          className="tab-pane fade show active"
                          id="pills-home"
                          role="tabpanel"
                          aria-labelledby="pills-home-tab"
                        >
                          <div className="buy-content-list">
                            <ul className="buy-content-ul">
                              <li>
                                <div className="buy-des-left">
                                  <h5>Amount</h5>
                                  {/* <h3>0.5432</h3> */}
                                  <input
                                    type="number"
                                    name="inputAmount"
                                    className="form-control"
                                    placeholder="Input Amount"
                                    value={inputAmount}
                                    min={0}
                                    onChange={(e) => {
                                      const value = e.target.value;
                                      if (value >= 0) {
                                        setInputAmount(value);
                                      }
                                    }}
                                  />
                                </div>
                                <div className="buy-des-right">
                                  <div className="dropdown">
                                    <a
                                      className="dropdown-toggle"
                                      href="#"
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                    >
                                      {inputToken}
                                    </a>
                                    <ul className="dropdown-menu">
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() =>
                                            handleTokenSelect("ETH")
                                          }
                                        >
                                          ETH
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() =>
                                            handleTokenSelect("WBTC")
                                          }
                                        >
                                          WBTC
                                        </a>
                                      </li>
                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() =>
                                            handleTokenSelect("USDT")
                                          }
                                        >
                                          USDT
                                        </a>
                                      </li>

                                      <li>
                                        <a
                                          className="dropdown-item"
                                          href="#"
                                          onClick={() =>
                                            handleTokenSelect("USD")
                                          }
                                        >
                                          USD (Fiat)
                                        </a>
                                      </li>
                                    </ul>
                                  </div>
                                </div>
                              </li>
                              {inputToken === "USD" ? (
                                <li style={{ display: "block" }}>
                                  <div className="buy-des-left">
                                    <h5>Wallet Address</h5>
                                    <input
                                      style={{
                                        width: "100%",
                                      }}
                                      type="text"
                                      name="walletAddress"
                                      className="form-control"
                                      placeholder="Receiver Wallet Address"
                                      value={depositWallet}
                                      onChange={(e) => {
                                        setDepositWallet(e.target.value);
                                        setIsValid(
                                          ethers.utils.isAddress(e.target.value)
                                        );
                                      }}
                                    />
                                  </div>
                                </li>
                              ) : null}

                              <li>
                                <div className="buy-des-left">
                                  <h5>Paid</h5>
                                  <input
                                    type="text"
                                    name="outputAmount"
                                    className="form-control"
                                    placeholder="Output Amount"
                                    value={outputAmount}
                                  />
                                </div>
                                <div className="buy-des-right">
                                  <div className="dropdown">
                                    <p className="dropdown-toggle">CUCC</p>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          <div className="ethereum-des">
                            <p>
                              1 {inputToken} = $
                              {(inputToken === "ETH"
                                ? ethBtcPrice.eth
                                : inputToken === "WBTC"
                                ? ethBtcPrice.btc
                                : 1) !== undefined
                                ? inputToken === "ETH"
                                  ? ethBtcPrice.eth
                                  : inputToken === "WBTC"
                                  ? ethBtcPrice.btc
                                  : 1
                                : 1}
                            </p>
                          </div>
                          <div className="buy-btn" onClick={handleBuyToken}>
                            <a className="btn">
                              Buy $
                              {(() => {
                                // Perform the calculation
                                let calculatedValue = 0;
                                if (inputAmount <= 0) {
                                  return 0;
                                }

                                if (inputToken === "ETH") {
                                  calculatedValue =
                                    ethBtcPrice.eth * inputAmount;
                                } else if (inputToken === "WBTC") {
                                  calculatedValue =
                                    ethBtcPrice.btc * inputAmount;
                                } else {
                                  calculatedValue = inputAmount;
                                }

                                calculatedValue = Number(calculatedValue);

                                return isNaN(calculatedValue)
                                  ? 0
                                  : calculatedValue.toFixed(2);
                              })()}
                            </a>
                          </div>
                        </div>
                      </div>
                      <StakeCards
                        cuccBalance={balance}
                        setLoading={setLoading}
                      />
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
        <Footer />
      </>
    </>
  );
};
