import React from "react";
import "react-toastify/dist/ReactToastify.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import { Signup } from "./pages/Signup"; // Change to default import
import { Home } from "./components/Home"; // Change to default import
import { NoPage } from "./pages/NoPage"; // Change to default import
import { ToastContainer } from "react-toastify";
import { ForgotPassword } from "./pages/ForgotPassword";
import { OtpVerification } from "./pages/OtpVerification";
import { ResetPassword } from "./pages/ResetPassword";
import { Dashboard } from "./components/dashboard/Dashboard";
import { MyProfile } from "./components/dashboard/MyProfile";
import { Transactions } from "./components/dashboard/Transactions";
import { ChangePassword } from "./components/dashboard/ChangePassword";
import { NotificationsSettings } from "./components/dashboard/NotificationsSettings";
import { ResOtpVerification } from "./pages/ResOtpVerification";
import { Privacy } from "./pages/Privacy";
import { TermCondition } from "./pages/TermCondition";
import { Cookies } from "./pages/Cookies";
import { Notifications } from "./components/dashboard/Notifications";
import { Staking } from "./components/dashboard/Staking";

function App() {
  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="login" element={<Login />} />
          <Route path="signup" element={<Signup />} />

          <Route path="/dashboard">
            <Route index element={<Dashboard />} />
            <Route path="my-profile" element={<MyProfile />} />
            <Route path="transactions" element={<Transactions />} />
            <Route path="staking" element={<Staking />} />
            <Route path="change-password" element={<ChangePassword />} />
            <Route
              path="notifications-settings"
              element={<NotificationsSettings />}
            />
            <Route path="notifications" element={<Notifications />} />
          </Route>

          <Route path="fotgot-password" element={<ForgotPassword />} />
          <Route path="otp-verification" element={<OtpVerification />} />
          <Route
            path="registration-otp-verification"
            element={<ResOtpVerification />}
          />
          <Route path="reset-password" element={<ResetPassword />} />
          <Route path="privacy" element={<Privacy />} />
          <Route path="term-condition" element={<TermCondition />} />
          <Route path="cookies" element={<Cookies />} />
          <Route path="fotgot-password" element={<ForgotPassword />} />

          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
