import React from "react";

import { updateNotification } from "../../services/api";

export const NotificationModal = ({ tx, isOpen, toggle }) => {
  return isOpen ? (
    <div
      className="modal fade transaction-approved-modal show"
      id="exampleModal"
      tabindex="-1"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
      style={isOpen ? { display: "block" } : "none"}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id="exampleModalLabel">
              Notification Modal
            </h5>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              onClick={toggle}
            ></button>
          </div>
          <div className="modal-body">
            <br />
            <br />

            <div className="transaction-info-list">
              <h6
                style={{
                  color: "#d88638",
                }}
              >
                {tx?.title}
              </h6>
              <br />

              <p dangerouslySetInnerHTML={{ __html: tx?.body }}></p>
            </div>
          </div>
          <button
            className="explorer-btn"
            onClick={async () => {
              await updateNotification({
                notificationId: tx?._id,
              });
              toggle();
            }}
          >
            Mark as read
          </button>
        </div>
      </div>
    </div>
  ) : null;
};
