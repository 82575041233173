import React, { useEffect, useState } from "react";
import { getAllFaq } from "../../services/api";
import { trimData } from "../../services/help";

const Faq = () => {
  const [faq, setFaq] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const output = await getAllFaq();
        const enabledFaq = output.filter((item) => item.isEnable);
        setFaq(enabledFaq);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <section className="faq-section section-padding" id="faq-section">
      {faq && faq.length > 0 ? (
        <div className="container">
          <div className="heading-text text-center">
            <h4>Frequently asked questions</h4>
          </div>
          <div className="accordion" id="accordionExample">
            {faq?.map((item, key) => (
              <div className="accordion-item" key={key}>
                <h2 className="accordion-header" id={`heading${key}`}>
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#collapse${key}`}
                    aria-expanded={key === 0 ? "true" : "false"} // Set aria-expanded to "true" for the first item, "false" for others
                    aria-controls={`collapse${key}`}
                  >
                    {trimData(item.question, 80)}
                  </button>
                </h2>
                <div
                  id={`collapse${key}`}
                  className={`accordion-collapse collapse ${
                    key === 0 ? "show" : ""
                  }`} // Add "show" class only for the first item
                  aria-labelledby={`heading${key}`}
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body">
                    <p>{item.answer}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : null}
    </section>
  );
};

export default Faq;
