import { Bounce, toast } from "react-toastify";

export const trimAddress = (address) => {
  if (address.length <= 15) {
    return address;
  } else {
    return address.slice(0, 10) + "..." + address.slice(-5);
  }
};

export const formatDate = (dateString) => {
  // Convert the date string to a Date object
  const date = new Date(dateString);

  // Array of month names
  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  // Get day, month, year, hours, and minutes
  const day = date.getDate();
  const monthIndex = date.getMonth();
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();

  // Format the date in "DD-Mon-YYYY hh:mmAM/PM" format
  const formattedDate = `${day}-${monthNames[monthIndex]}-${year} ${
    hours % 12 || 12
  }:${minutes < 10 ? "0" + minutes : minutes}${hours >= 12 ? " PM" : " AM"}`;

  return formattedDate;
};

export const formatDateToInputValue = (dateString) => {
  const date = new Date(dateString);
  const pad = (num) => (num < 10 ? `0${num}` : num);
  return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
    date.getDate()
  )}`;
};

export const trimData = (data, length) => {
  if (data === undefined || data === null) {
    return "";
  }
  if (data.length <= length) {
    return data;
  } else {
    return data.slice(0, length) + "...";
  }
};

export const alertMsg = (status, msg) => {
  toast.dismiss();
  if (status == "success") {
    toast.success(msg, {
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      transition: Bounce,
    });
  } else {
    toast.error(msg, {
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
      theme: "colored",
      transition: Bounce,
    });
  }
};

export const getCoinPrice = async () => {
  const response = await fetch(
    "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin%2Cethereum%2Ctether&vs_currencies=usd&include_24hr_change=true&precision=2",
    {
      method: "GET",
      headers: {
        accept: "application/json",
        "x-cg-api-key": "CG-tSwDtVsi4HssjvJboRfkSZvH",
      },
    }
  );
  return response.json();
};

export const getContractError = async (errorMessage) => {
  const regex = /(?<=\(reason="execution reverted: )(.*?)(?=",)/;
  const match = errorMessage.toString().match(regex);
  if (match) {
    const specificData = match[0];
    console.log("specificData : ", specificData);
    return specificData;
  } else {
    return null;
  }
};

export const removeHTMLTags = (text) => {
  return text.replace(/<\/?p>/g, ""); // Replace <p> or </p> with an empty string
};

export const remove_p_br_Tags = (text) => {
  // Remove <p> and </p> tags
  let result = text.replace(/<\/?p>/gi, "");
  // Remove <br> tags
  result = result.replace(/<br\s*\/?>/gi, "");
  // Remove <li> tags
  result = result.replace(/<li\s*\/?>/gi, "");
  // Remove <span> tags
  result = result.replace(/<\/?span.*?>/gi, "");
  return result;
};

export const secondToMonth = (seconds) => {
  const months = Math.floor(seconds / (3600 * 24 * 30));
  return months;
};

export const maxDate = () => {
  const today = new Date();
  const year = today.getFullYear() - 18; // Subtract Suces from the current year
  const month = ("0" + (today.getMonth() + 1)).slice(-2);
  const day = ("0" + today.getDate()).slice(-2);
  return `${year}-${month}-${day}`;
};
