import React from "react";

export const NewFeature = ({
  f2title1,
  f2subtitle1,
  f2title2,
  f2subtitle2,
}) => {
  return (
    <div>
      <section className="new-feature-sec section-padding">
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-md-4">
              <figure className="mb-0">
                <img src={process.env.PUBLIC_URL + "assets/images/new-1.png"} />
              </figure>
            </div>
            <div className="col-md-6">
              <div className="heading-text">
                <h4>{f2title1}</h4>
                <p>{f2subtitle1}</p>
              </div>
            </div>
          </div>
          <div className="row justify-content-between align-items-center">
            <div className="col-md-6">
              <div className="heading-text">
                <h4>{f2title2}</h4>
                <p>{f2subtitle2}</p>
              </div>
            </div>
            <div className="col-md-4">
              <figure className="mb-0">
                <img src={process.env.PUBLIC_URL + "assets/images/new-2.png"} />
              </figure>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
