import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  return (
    <footer>
      <div className="container">
        <div className="top-footer section-padding">
          <div className="row">
            <div className="col-md-3">
              <div className="footer-logo">
                <a href="#!">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/footer-logo.png"
                    }
                    alt="logo"
                  />
                </a>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-menu">
                <h3>Company</h3>
                <ul>
                  <li>
                    <a href="/#about-section">About us</a>
                  </li>
                  <li>
                    <a href="/#faq-section">FAQ'S</a>
                  </li>
                  <li>
                    <a href="/#inquiry">Contact us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="footer-menu">
                <h3>LEGAL</h3>
                <ul>
                  <li>
                    <a
                      onClick={() => {
                        navigate("/term-condition");
                      }}
                    >
                      Terms of service
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        navigate("/privacy");
                      }}
                    >
                      Privacy policy
                    </a>
                  </li>
                  <li>
                    <a
                      onClick={() => {
                        navigate("/cookies");
                      }}
                    >
                      Cookie policy
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-3">
              <div className="social-box">
                <h3>Connect with us</h3>
                <ul>
                  <li>
                    <a href="https://x.com/?lang=en" target="_blank">
                      <i className="ti ti-brand-x"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/" target="_blank">
                      <i className="ti ti-brand-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://discord.com/" target="_blank">
                      <i className="ti ti-device-gamepad-2"></i>
                    </a>
                  </li>
                  <li>
                    <a href="https://telegram.org/" target="_blank">
                      <i className="ti ti-brand-telegram"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="bottom-footer">
          <p>Copyright © 2024. All Rights Reserved. CUCC</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
