import { Contract, ethers } from "ethers";
import { tokenABI } from "./ABI/TokenContractABI";
import {
  CopperAddress,
  PresaleAddress,
  StakingContractAddress,
  USDTAddress,
  WBTCAddress,
} from "./contract";
import { presaleABI } from "./ABI/SaleContractABI";
import { getContractError } from "../services/help";
import { StakingABI } from "./ABI/StakingABI";

const approveAmount = "1000000000000000000000000";

// const RPC_URL = "https://data-seed-prebsc-1-s1.binance.org:8545/";
const RPC_URL =
  "https://eth-mainnet.g.alchemy.com/v2/HZGTgTPiqB408bYkAdUFeDOTedqp4DBA"; //"https://1rpc.io/sepolia"; // "https://eth-sepolia.g.alchemy.com/v2/demo"; // "https://api.zan.top/node/v1/eth/sepolia/public"; //

async function getReadContract(CONTRACT_ADDRESS, CONTRACT_ABI) {
  // Initialize provider
  const provider = new ethers.providers.JsonRpcProvider(RPC_URL);
  // Create a new contract instance
  const contract = new ethers.Contract(
    CONTRACT_ADDRESS,
    CONTRACT_ABI,
    provider
  );
  return contract;
}

export async function getBalanceOf(userWalletAddress, TokenContractAddress) {
  // const contractInstance = new Contract(TokenContractAddress, tokenABI, signer);
  const contractInstance = await getReadContract(
    TokenContractAddress,
    tokenABI
  );
  let balance = await contractInstance.balanceOf(userWalletAddress);
  let decimal = Number(await getDecimal(TokenContractAddress));
  console.log("token deicmal is : " + decimal);
  return Number(balance) / 10 ** decimal;
}

export async function getDecimal(tokenContractAddress) {
  const contractInstance = await getReadContract(
    tokenContractAddress,
    tokenABI
  );
  // const contractInstance = new Contract(tokenContractAddress, tokenABI, signer);
  let decimal = await contractInstance.decimals();
  return Number(decimal);
}

export async function getEthBtcPrice() {
  const contractInstance = await getReadContract(PresaleAddress, presaleABI);

  // new Contract(PresaleAddress, presaleABI, signer);
  let price = await contractInstance.getEthBtcPrice();
  console.log("contract helper eth btc price is : " + price);
  return price;
}

export async function checkApproval(userWalletAddress) {
  const userApproval = {
    usdt: 0,
    wbtc: 0,
  };
  const usdtInstance = await getReadContract(USDTAddress, tokenABI);
  let usdtAllowance = await usdtInstance.allowance(
    userWalletAddress,
    PresaleAddress
  );
  userApproval.usdt = ethers.utils.formatUnits(usdtAllowance, 6); // Assuming USDT has 6 decimals

  const wbtcInstance = await getReadContract(WBTCAddress, tokenABI);
  let wbtcAllowance = await wbtcInstance.allowance(
    userWalletAddress,
    PresaleAddress
  );
  userApproval.wbtc = ethers.utils.formatUnits(wbtcAllowance, 8); // Assuming WBTC has 8 decimals

  return userApproval;
}
//
export async function checkApprovalForStaking(userWalletAddress) {
  const copperInstance = await getReadContract(CopperAddress, tokenABI);
  let copperAllowance = await copperInstance.allowance(
    userWalletAddress,
    StakingContractAddress
  );
  let userApproval = ethers.utils.formatUnits(copperAllowance, 18);
  return userApproval;
}

export async function setApproval(tokenContractAddress, signer) {
  const contractInstance = new Contract(tokenContractAddress, tokenABI, signer);

  try {
    const transaction = await contractInstance.approve(
      PresaleAddress,
      approveAmount,
      {
        // gasLimit: 3000000,
      }
    );
    await transaction.wait(); // Wait for the transaction to be mined
    return transaction;
  } catch (error) {
    console.error("Transaction failed:", error);
    throw new Error("Transaction failed: " + error.message);
  }
}

export async function giveApprovalToStaking(signer) {
  const contractInstance = new Contract(CopperAddress, tokenABI, signer);

  try {
    const transaction = await contractInstance.approve(
      StakingContractAddress,
      approveAmount,
      {
        gasLimit: 3000000,
      }
    );
    await transaction.wait(); // Wait for the transaction to be mined
    return transaction;
  } catch (error) {
    console.error("Transaction failed:", error);
    throw new Error("Transaction failed: " + error.message);
  }
}

export async function buyWithUSDT(usdtAmount, signer) {
  console.log("buyWithUSDT called from contractHelper ");

  const contractInstance = new Contract(PresaleAddress, presaleABI, signer);
  // Ensure usdtAmount is converted to the appropriate format
  const usdtAmountInWei = ethers.utils.parseUnits(usdtAmount.toString(), 6); // Assuming USDT has 6 decimals

  try {
    const gasLimit = await contractInstance.estimateGas.buyWithUSDT(
      usdtAmountInWei,
      {
        // gasLimit: 3000000,
      }
    );

    const transaction = await contractInstance.buyWithUSDT(usdtAmountInWei, {
      gasLimit: gasLimit, // Manually set a gas limit
      // gasLimit: 3000000,
    });
    await transaction.wait(); // Wait for the transaction to be mined
    return transaction;
  } catch (error) {
    console.log("error : " + error);
    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas"))
        return "Insufficient ETH Amount";
      else {
        return error?.data?.message;
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      return errorMessage;
    } else {
      return "something went wrong, check gass fee";
    }
  }
}

export async function buyWithWBTC(usdtAmount, signer) {
  console.log("buyWithWBTC called from contractHelper ");
  const contractInstance = new Contract(PresaleAddress, presaleABI, signer);
  // Ensure usdtAmount is converted to the appropriate format
  const AmountInWei = ethers.utils.parseUnits(usdtAmount.toString(), 8); // Assuming USDT has 6 decimals

  try {
    const gasLimit = await contractInstance.estimateGas.buyWithWBTC(
      AmountInWei,
      {
        gasLimit: 3000000,
      }
    );

    const transaction = await contractInstance.buyWithWBTC(AmountInWei, {
      gasLimit: gasLimit, // Manually set a gas limit
      // gasLimit: 3000000,
    });
    await transaction.wait(); // Wait for the transaction to be mined
    return transaction;
  } catch (error) {
    console.log("error : " + error);
    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas"))
        return "Insufficient ETH Amount";
      else {
        return error?.data?.message;
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      return errorMessage;
    } else {
      return "something went wrong, check gass fee";
    }
  }
}

export async function buyWithETH(ethAmount, signer) {
  console.log("buyWithETH called from contractHelper ");
  const contractInstance = new Contract(PresaleAddress, presaleABI, signer);

  try {
    const gasLimit = await contractInstance.estimateGas.buyWithETH({
      value: ethers.utils.parseEther(ethAmount.toString()), // Convert ethAmount to Wei
      gasLimit: 3000000,
    });

    const transaction = await contractInstance.buyWithETH({
      gasLimit: gasLimit, // Manually set a gas limit
      value: ethers.utils.parseEther(ethAmount.toString()), // Convert ethAmount to Wei
    });

    await transaction.wait(); // Wait for the transaction to be mined
    return transaction;
  } catch (error) {
    console.log("error : " + error.message, typeof error.message);

    if (error?.message) {
      if (error?.message.includes("insufficient funds"))
        return "Insufficient ETH Amount";
      else {
        return error?.message;
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      return errorMessage;
    } else {
      return "something went wrong, check gass fee";
    }
  }
}

export async function getStakePlans() {
  const contractInstance = await getReadContract(
    StakingContractAddress,
    StakingABI
  );
  let stakePlans = await contractInstance.getAllStakingPlans();
  return stakePlans;
}

export async function stakeToken(planId, tokens, signer) {
  const response = {
    transaction: "",
    event: "",
  };
  console.log(
    "stakeToken called from contractHelper planId, tokens, : ",
    planId,
    tokens
  );
  const contractInstance = new Contract(
    StakingContractAddress,
    StakingABI,
    signer
  );

  try {
    const gasLimit = await contractInstance.estimateGas.stakeTokens(
      planId,
      tokens,
      {
        gasLimit: 3000000,
      }
    );

    const transaction = await contractInstance.stakeTokens(planId, tokens, {
      gasLimit: gasLimit, // Manually set a gas limit
    });

    const receipt = await transaction.wait(); // Wait for the transaction to be mined
    console.log(
      "Transaction receipt?.events[1] : ",
      receipt?.events[1],
      receipt?.events[1]?.args
    );

    console.log(
      "Events emitted during the transaction:",
      receipt?.events[1]?.args
    );
    response.transaction = transaction;
    response.event = receipt?.events[1]?.args;

    return response;
  } catch (error) {
    console.log("error : " + error);
    console.log("error?.data?.message : ", error?.data?.message);
    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas"))
        return "Insufficient ETH Amount";
      else {
        return error?.data?.message;
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      return errorMessage;
    } else {
      return "something went wrong, check gass fee";
    }
  }
}

// unstakeTokens
export async function unstakeTokens(stakeId, signer) {
  console.log(
    "stakeToken called from contractHelper planId, tokens, : ",
    stakeId
  );
  const contractInstance = new Contract(
    StakingContractAddress,
    StakingABI,
    signer
  );

  try {
    const gasLimit = await contractInstance.estimateGas.unstakeTokens(
      stakeId,

      {
        gasLimit: 3000000,
      }
    );

    const transaction = await contractInstance.unstakeTokens(stakeId, {
      gasLimit: gasLimit, // Manually set a gas limit
    });

    await transaction.wait(); // Wait for the transaction to be mined
    return transaction;
  } catch (error) {
    console.log("error : " + error);
    console.log("error?.data?.message : ", error?.data?.message);
    if (error?.data?.message) {
      if (error?.data?.message.includes("err: insufficient funds for gas"))
        return "Insufficient ETH Amount";
      else {
        return error?.data?.message;
      }
    }
    let errorMessage = await getContractError(error);
    if (errorMessage) {
      return errorMessage;
    } else {
      return "something went wrong, check gass fee";
    }
  }
}
