import React, { useState } from "react";
import { createInquiry } from "../../services/api";

export const Inquiry = () => {
  const [inquiryData, setInquiryData] = useState({
    name: "",
    email: "",
    subject: "Problems Logging In", // Default value
    message: "",
  });

  const [errors, setErrors] = useState({});

  const subjects = [
    "Problems Logging In",
    "Problems Signing Up",
    "Forgot Password",
    "Account Security Concerns",
    "Other",
  ];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setInquiryData({
      ...inquiryData,
      [name]: value,
    });
  };

  const validateForm = () => {
    const { name, email, subject, message } = inquiryData;
    const newErrors = {};

    if (!name) {
      newErrors.name = "Name is required.";
    }

    if (!email) {
      newErrors.email = "Email is required.";
    } else if (
      !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email)
    ) {
      newErrors.email = "Enter a valid email address.";
    }

    if (!subject) {
      newErrors.subject = "Subject is required.";
    }

    if (!message) {
      newErrors.message = "Message is required.";
    }
    if (message.length > 500) {
      newErrors.message = "Maximum 500 Charactors Allow.";
    }

    setErrors(newErrors);
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length === 0) {
      try {
        await createInquiry(inquiryData);

        // Reset the form after successful submission
        setInquiryData({
          name: "",
          email: "",
          subject: subjects[0], // Reset to default subject
          message: "",
        });
      } catch (error) {
        console.error("Error submitting inquiry:", error);
      }
    }
  };

  return (
    <div>
      <section className="get-touch-section section-padding" id="inquiry">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="get-touch-img-box">
                <img
                  src={process.env.PUBLIC_URL + "assets/images/get-img.png"}
                  alt="get"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="get-touch-form">
                <h3>Get in touch with us</h3>
                <form className="form-box" onSubmit={handleSubmit}>
                  <div className="form-group">
                    <label>Name</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-user"></i>
                      </div>
                      <input
                        type="text"
                        name="name"
                        maxLength={50}
                        className="form-control"
                        placeholder="Enter your name"
                        value={inquiryData.name}
                        onChange={handleInputChange}
                      />
                    </div>
                    {errors.name && (
                      <small className="text-danger">{errors.name}</small>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Email Address</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-mail"></i>
                      </div>
                      <input
                        type="text"
                        name="email"
                        maxLength={50}
                        className="form-control"
                        placeholder="Enter your email address"
                        value={inquiryData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    {errors.email && (
                      <small className="text-danger">{errors.email}</small>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Subject</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-list-details"></i>
                      </div>
                      <select
                        name="subject"
                        className="form-control"
                        value={inquiryData.subject}
                        onChange={handleInputChange}
                      >
                        {subjects.map((subject, index) => (
                          <option key={index} value={subject}>
                            {subject}
                          </option>
                        ))}
                      </select>
                    </div>
                    {errors.subject && (
                      <small className="text-danger">{errors.subject}</small>
                    )}
                  </div>
                  <div className="form-group">
                    <label>Message</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-message"></i>
                      </div>
                      <textarea
                        name="message"
                        className="form-control"
                        placeholder="Type your message here..."
                        value={inquiryData.message}
                        maxLength={500}
                        onChange={handleInputChange}
                      ></textarea>
                    </div>
                    {errors.message && (
                      <small className="text-danger">{errors.message}</small>
                    )}
                  </div>
                  <div className="form-btn">
                    <button type="submit" className="btn">
                      Submit your query
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
