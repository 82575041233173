import { type } from "@testing-library/user-event/dist/type";
import axios from "axios";
import { alertMsg } from "./help";

const API = process.env.REACT_APP_API_URL;

export const userSignAPI = async (data) => {
  try {
    const formData = new FormData();
    formData.append("firstName", data.firstName);
    formData.append("lastName", data.lastName);
    formData.append("email", data.email);
    formData.append("username", data.email);
    formData.append("password", data.password);
    formData.append("dob", data.dob);
    formData.append("keyDoc", data.keyDoc[0]); // Append the file

    console.log("formData : ", formData, typeof formData);

    // const endPoint = process.env.REACT_APP_API_URL + "/api/v1/auth/admin-login";
    const endPoint = `${API}/api/v1/signup`;

    console.log("endPoint : ", endPoint);
    console.log("Data to api : ", formData);

    const response = await axios.post(endPoint, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });

    console.log("response : ", response?.data);
    alertMsg("success", response?.data?.message);

    return true;
  } catch (e) {
    console.log("EEEEE : ", e);
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    alertMsg("error", e?.response?.data?.errors[0]?.msg);
    return false;
  }
};

export const userLoginAPI = async (email, password) => {
  try {
    // const endPoint = process.env.REACT_APP_API_URL + "/api/v1/auth/admin-login";
    const endPoint = `${API}/api/v1/auth/login`;

    let data = {
      email: email,
      password: password,
    };

    console.log("endPoint : ", endPoint);
    const response = await axios.post(endPoint, data);
    alertMsg("success", response?.data?.message);

    console.log("response : ", response?.data);

    return response?.data;
  } catch (e) {
    let error = e?.response?.data?.message;
    console.log("Error from login api :", error);
    alertMsg("error", error);
    return false;
  }
};

// localhost:8000/api/v1/verify-signup-otp
export const registrationOtpVerify = async (otp, token) => {
  try {
    const endPoint = `${API}/api/v1/auth/verify-signup-otp`;

    let data = {
      otp: otp,
    };
    const token = localStorage.getItem("accessToken");

    console.log("endPoint:", endPoint, "And data is:", data);

    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("response : ", response?.data);
    alertMsg("success", response?.data?.message);

    // return respnse.data;
    return response?.data;
  } catch (e) {
    console.log("EEE : ", e);
    let error = e?.response?.data?.message;
    console.log("Error from login api :", error);

    alertMsg("error", error);

    // alert(e?.response?.data?.errors[0]?.msg);
    return false;
  }
};

// localhost:8000/api/v1/forgot-password
export const forgotPasswordOtpSend = async (email) => {
  try {
    // const endPoint = process.env.REACT_APP_API_URL + "/api/v1/auth/admin-login";
    const endPoint = `${API}/api/v1/forgot-password`;

    let data = {
      email: email,
    };

    console.log("endPoint:", endPoint, "And data is:", data);

    const response = await axios.post(endPoint, data);

    // const response = await axios.post(endPoint, {
    //   email: "rahul.saini@devtechnosys.info",
    // });
    console.log("response : ", response?.data);

    alertMsg("success", response?.data?.message);

    // return respnse.data;
    return response?.data;
  } catch (e) {
    console.log("EEE : ", e);
    let error = e?.response?.data?.message;
    console.log("Error from login api :", error);

    alertMsg("error", error);

    // alert(e?.response?.data?.errors[0]?.msg);
    return false;
  }
};

// localhost:8000/api/v1/auth/forgot-password-otp-verify
export const forgotPasswordOtpVerify = async (otp, token) => {
  try {
    const endPoint = `${API}/api/v1/auth/forgot-password-otp-verify`;

    let data = {
      otp: otp,
    };
    const token = localStorage.getItem("accessToken");

    console.log("endPoint:", endPoint, "And data is:", data);

    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("response : ", response?.data);
    alertMsg("success", response?.data?.message);

    // return respnse.data;
    return response?.data;
  } catch (e) {
    console.log("EEE : ", e);
    let error = e?.response?.data?.message;
    console.log("Error from login api :", error);

    alertMsg("error", error);

    // alert(e?.response?.data?.errors[0]?.msg);
    return false;
  }
};

// localhost:8000/api/v1/auth/reset-password-by-token
export const resetPasswordByToken = async (otp, newPassword, token) => {
  try {
    const endPoint = `${API}/api/v1/auth/reset-password-by-token`;

    let data = {
      otp: otp,
      newPassword: newPassword,
    };

    const token = localStorage.getItem("accessToken");

    console.log("endPoint:", endPoint, "And data is:", data);

    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("response : ", response?.data);

    alertMsg("success", response?.data?.message);

    // return respnse.data;
    return response?.data;
  } catch (e) {
    console.log("EEE : ", e);
    let error = e?.response?.data?.message;
    console.log("Error from login api :", error);

    alertMsg("error", error);

    // alert(e?.response?.data?.errors[0]?.msg);
    return false;
  }
};

export const getAllFaq = async () => {
  try {
    const endPoint = `${API}/api/v1/get-all-faq`;

    // console.log("endPoint : ", endPoint);
    const respnse = await axios.get(endPoint);

    // console.log("get faq api res : ", respnse?.data?.faq);

    return respnse?.data?.faq;
  } catch (e) {
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    alert(e?.response?.data?.errors[0]?.msg);
    return {
      token: "",
    };
  }
};

export const getUserProfileByToken = async () => {
  try {
    const endPoint = `${API}/api/v1/auth/user`;
    const token = localStorage.getItem("accessToken");
    console.log("endPoint : ", endPoint, token);
    const respnse = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("get user api res : ", respnse?.data);

    return respnse?.data;
  } catch (e) {
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    alert(e?.response?.data?.errors[0]?.msg);

    return {
      token: "",
    };
  }
};

// /auth/update-user-by-id

export const updateUserByToken = async (userData) => {
  try {
    const endPoint = `${API}/api/v1/auth/update-user-by-id`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.log("Token not found");
      return;
    }

    const response = await axios.patch(endPoint, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    alertMsg("success", response?.data?.message);

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    alertMsg("error", e?.response?.data?.errors[0]?.msg);

    if (e?.response?.status === 401) {
      return { token: "" };
    }
    console.error("Error:", e?.response?.data?.errors[0]?.msg);

    return { token: "" };
  }
};

export const changePasswordByToken = async (userData) => {
  try {
    const endPoint = `${API}/api/v1/auth/change-password-by-token`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.log("Token not found");
      return;
    }

    const response = await axios.post(endPoint, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("change password api call response : ", response);

    if (response?.data?.user) {
      await alertMsg("success", response?.data?.message);
    } else {
      await alertMsg("error", response?.data?.message);
    }

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    let eMessage1 = e?.response?.data?.errors?.msg;
    let eMessage2 = e?.response?.data?.message;
    console.log("RRR : ", eMessage1, eMessage2);
    alertMsg("error", eMessage1);
    alertMsg("error", eMessage2);
    return { token: "" };
  }
};

export const getAllContent = async (_id) => {
  try {
    const endPoint = `${API}/api/v1/get-content`;

    console.log("endPoint : ", endPoint);
    const respnse = await axios.get(endPoint);

    return respnse.data;
  } catch (e) {
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
  }
};

// localhost:8000/api/v1/auth/buy-tx
export const createBuyToken = async (userData) => {
  try {
    const endPoint = `${API}/api/v1/auth/buy-tx`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.log("Token not found");
      return;
    }

    const response = await axios.post(endPoint, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("change password api call response : ", response);

    if (response?.data) {
      await alertMsg("success", response?.data?.message);
    } else {
      await alertMsg("error", response?.data?.error);
    }

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    let eMessage1 = e?.response?.data?.errors?.msg;
    let eMessage2 = e?.response?.data?.message;
    console.log("RRR : ", eMessage1, eMessage2);
    alertMsg("error", eMessage1);
    alertMsg("error", eMessage2);
    return { token: "" };
  }
};

export const createStakeToken = async (userData) => {
  try {
    const endPoint = `${API}/api/v1/auth/stake-tx`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.log("Token not found");
      return;
    }

    const response = await axios.post(endPoint, userData, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("change password api call response : ", response);

    if (response?.data) {
      await alertMsg("success", response?.data?.message);
    } else {
      await alertMsg("error", response?.data?.error);
    }

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    let eMessage1 = e?.response?.data?.errors?.msg;
    let eMessage2 = e?.response?.data?.message;
    console.log("RRR : ", eMessage1, eMessage2);
    alertMsg("error", eMessage1);
    alertMsg("error", eMessage2);
    return { token: "" };
  }
};

export const getAllStakeTx = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/user-stake-tx`;
    // const endPoint = "http://localhost:8000/api/v1/auth/user-buy-tx";
    const token = localStorage.getItem("accessToken");

    console.log("Access Token and Endpoint : ", token, endPoint);

    console.log("endPoint : ", endPoint);
    const respnse = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log("response : ", respnse?.data);
    return respnse?.data;
  } catch (e) {
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    alert(e?.response?.data?.errors[0]?.msg);

    return {
      token: "",
    };
  }
};

export const getAllBuyTx = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/user-buy-tx`;
    // const endPoint = "http://localhost:8000/api/v1/auth/user-buy-tx";
    const token = localStorage.getItem("accessToken");
    console.log("Access Token and Endpoint : ", token, endPoint);

    console.log("endPoint : ", endPoint);
    const respnse = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log("response : ", respnse?.data);
    return respnse?.data;
  } catch (e) {
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    alert(e?.response?.data?.errors[0]?.msg);

    return {
      token: "",
    };
  }
};

export const getAllNotifications = async (data) => {
  try {
    // localhost:8000/api/v1/auth/get-user-notification-count
    const endPoint = `${API}/api/v1/auth/get-users-all-notification`;

    const token = localStorage.getItem("accessToken");
    console.log("Access Token and Endpoint : ", token, endPoint);

    console.log("endPoint : ", endPoint);
    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log("response : ", response?.data);
    return response?.data;
  } catch (e) {
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    alert(e?.response?.data?.errors[0]?.msg);

    return {
      token: "",
    };
  }
};

export const getUserNotificationsCount = async (data) => {
  try {
    // localhost:8000/api/v1/auth/get-user-notification-count
    const endPoint = `${API}/api/v1/auth/get-user-notification-count`;

    const token = localStorage.getItem("accessToken");
    console.log("Access Token and Endpoint : ", token, endPoint);

    console.log("endPoint : ", endPoint);
    const response = await axios.get(endPoint, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });
    console.log("response : ", response?.data);
    return response?.data;
  } catch (e) {
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    alert(e?.response?.data?.errors[0]?.msg);

    return {
      token: "",
    };
  }
};

export const updateNotification = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/update-notification`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.log("Token not found");
      return;
    }

    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("change password api call response : ", response);

    if (response?.data) {
      await alertMsg("success", response?.data?.message);
    } else {
      await alertMsg("error", response?.data?.error);
    }

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    let eMessage1 = e?.response?.data?.errors?.msg;
    let eMessage2 = e?.response?.data?.message;
    console.log("RRR : ", eMessage1, eMessage2);
    alertMsg("error", eMessage1);
    alertMsg("error", eMessage2);
    return { token: "" };
  }
};

export const createInquiry = async (data) => {
  try {
    const endPoint = `${API}/api/v1/create-inquiry`;

    const response = await axios.post(endPoint, data);

    console.log("createInquiry  api call response : ", response);

    if (response?.data) {
      await alertMsg("success", response?.data?.message);
    } else {
      await alertMsg("error", response?.data?.error);
    }

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    let eMessage1 = e?.response?.data?.errors?.msg;
    let eMessage2 = e?.response?.data?.message;
    console.log("RRR : ", eMessage1, eMessage2);
    alertMsg("error", eMessage1);
    alertMsg("error", eMessage2);
    return { token: "" };
  }
};

export const getCopperLivePrice = async (data) => {
  try {
    // localhost:8000/api/v1/get-copper-price
    const endPoint = `${API}/api/v1/get-copper-price`;

    // console.log("Endpoint : ", endPoint);

    const response = await axios.get(endPoint);
    // console.log("response : ", response?.data);
    return response;
  } catch (e) {
    console.error("Error:", e?.response?.data?.errors[0]?.msg);
    // alert(e?.response?.data?.errors[0]?.msg);
    return {
      token: "",
    };
  }
};

// localhost:8000/api/v1/auth/update-user-stake
export const updateStake = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/update-user-stake`;
    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.log("Token not found");
      return;
    }

    const response = await axios.patch(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("updateStake  api call response : ", response);

    if (response?.data) {
      await alertMsg("success", response?.data?.message);
    } else {
      await alertMsg("error", response?.data?.error);
    }

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    let eMessage1 = e?.response?.data?.errors?.msg;
    let eMessage2 = e?.response?.data?.message;
    console.log("RRR : ", eMessage1, eMessage2);
    alertMsg("error", eMessage1);
    alertMsg("error", eMessage2);
    return { token: "" };
  }
};

// localhost:8000/api/v1/auth/generate-kyc-sdk-link
export const generateKycLink = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/generate-kyc-sdk-link`;

    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.log("Token not found");
      return;
    }

    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("generateKycLink  api call response : ", response);

    if (response?.data?.error) {
      await alertMsg("error", response?.data?.error);
      return;
    }

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    let eMessage1 = e?.response?.data?.errors?.msg;
    let eMessage2 = e?.response?.data?.message;
    console.log("RRR : ", eMessage1, eMessage2);
    alertMsg("error", eMessage1);
    alertMsg("error", eMessage2);
    return { token: "" };
  }
};

// localhost:8000/api/v1/auth/create-card-payment
export const generatePaymentLink = async (data) => {
  try {
    const endPoint = `${API}/api/v1/auth/create-card-payment`;
    console.log("endPoint for generatePaymentLink : ", endPoint);

    const token = localStorage.getItem("accessToken");
    if (!token) {
      console.log("Token not found");
      return;
    }

    const response = await axios.post(endPoint, data, {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    });

    console.log("generateKycLink  api call response : ", response);

    if (response?.data?.error) {
      await alertMsg("error", response?.data?.error);
      return;
    }

    return response?.data;
  } catch (e) {
    console.log("Error:", e);
    let eMessage1 = e?.response?.data?.errors?.msg;
    let eMessage2 = e?.response?.data?.message;
    console.log("RRR : ", eMessage1, eMessage2);
    alertMsg("error", eMessage1);
    alertMsg("error", eMessage2);
    return { token: "" };
  }
};
