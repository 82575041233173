import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Footer from "../landingPage/Footer";
import {
  changePasswordByToken,
  getUserProfileByToken,
} from "../../services/api";

import Header from "../landingPage/Header";
import { useForm } from "react-hook-form";
import { Loader } from "../Loader";

export const ChangePassword = () => {
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const [user, setUser] = useState({
    firstname: "",
    lastname: "",
    username: "",
    email: "",
    dob: "",
    verify: false,
  });

  const onSubmit = async (data) => {
    console.log("onSubmit called with data: ", data);
    setLoading(true);
    setPasswordMatchError("");

    if (data.newPassword !== data.confirmPassword) {
      setPasswordMatchError("Confirm passwords do not match");
      setLoading(false);
      return;
    }

    try {
      const response = await changePasswordByToken(data);
      if (response?.user) {
        localStorage.removeItem("accessToken");
        navigate("/login");
      }
    } catch (error) {}
    setLoading(false);
  };

  useEffect(() => {
    async function fetchUser() {
      try {
        const userData = await getUserProfileByToken();
        setUser(userData);

        const token = localStorage.getItem("accessToken");
        if (!token) {
          navigate("/");
        }
      } catch (error) {
        navigate("/"); // Optionally redirect to login if there's an error fetching the user
      }
    }

    fetchUser();
  }, [navigate]);

  return (
    <>
      {loading ? <Loader /> : null}
      <div className="dashboard-bg">
        <Header />
        <div className="container">
          <div className="container">
            <div className="dashboard-content-box change-password-bg">
              <form onSubmit={handleSubmit(onSubmit)}>
                <section className="my-profile-form">
                  <h3>Change password</h3>
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Current Password</label>
                        <div className="input-group">
                          <div className="input-group-icon">
                            <i className="ti ti-lock"></i>
                          </div>
                          <input
                            type="password"
                            required={true}
                            name="oldPassword"
                            className="form-control"
                            placeholder="Enter current password"
                            {...register("oldPassword", {
                              required: "Current password is required",
                            })}
                          />
                        </div>
                        {errors.oldPassword && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "10px",
                            }}
                          >
                            {errors.oldPassword.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>New Password</label>
                        <div className="input-group">
                          <div className="input-group-icon">
                            <i className="ti ti-lock"></i>
                          </div>
                          <input
                            type="password"
                            required={true}
                            name="newPassword"
                            className="form-control"
                            placeholder="Enter new password"
                            {...register("newPassword", {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must be at least 8 characters long",
                              },
                              validate: {
                                capitalLetter: (value) =>
                                  /[A-Z]/.test(value) ||
                                  "Password must include at least one capital letter",
                                smallLetter: (value) =>
                                  /[a-z]/.test(value) ||
                                  "Password must include at least one small letter",
                                hasNumber: (value) =>
                                  /\d/.test(value) ||
                                  "Password must include at least one number",
                                hasSpecialChar: (value) =>
                                  /[@$!%*?&]/.test(value) ||
                                  "Password must include at least one special character",
                                noSpaces: (value) =>
                                  /^\S*$/.test(value) ||
                                  "Password must not contain spaces",
                              },
                            })}
                          />
                        </div>
                        {errors.password && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "10px",
                            }}
                          >
                            {errors.password.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group">
                        <label>Confirm New Password</label>
                        <div className="input-group">
                          <div className="input-group-icon">
                            <i className="ti ti-lock"></i>
                          </div>
                          <input
                            type="password"
                            name="confirmPassword"
                            className="form-control"
                            placeholder="Enter confirm new password"
                            required={true}
                            {...register("confirmPassword", {
                              required: "Confirm password is required",
                            })}
                          />
                        </div>
                        {errors.confirmPassword && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "10px",
                            }}
                          >
                            {errors.confirmPassword.message}
                          </p>
                        )}
                        {passwordMatchError && (
                          <p
                            style={{
                              color: "red",
                              fontSize: "14px",
                              marginTop: "10px",
                            }}
                          >
                            {passwordMatchError}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="update-profile-btn">
                      <button className="btn" type="submit" disabled={loading}>
                        {loading ? "Updating..." : "Update Password"}
                      </button>
                    </div>
                  </div>
                </section>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
