import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { userLoginAPI } from "../services/api";
import { useDispatch } from "react-redux";
import { LoginDetails } from "../reduxToolkit/slice/auth";
import { login } from "../reduxToolkit/slice/auth";

export const Login = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPass, setShowPass] = useState(false);

  const handleSignup = () => {
    navigate("/signup");
  };
  const togglePasswordVisibility = () => {
    setShowPass(!showPass); // Correct way to toggle state
  };

  const onSubmit = async (data) => {
    console.log(data.email, data.password);
    const output = await userLoginAPI(data.email, data.password);
    console.log("output from user login : ", output, output?.user?.token);
    // Example logic to handle login response
    if (output?.user?.token) {
      localStorage.setItem("accessToken", output?.user?.token);

      // Dispatch the LoginDetails action to update the Redux state
      dispatch(
        login({
          userId: output.user._id,
          firstname: output.user.firstname,
          lastname: output.user.lastname,
          username: output.user.username,
          email: output.user.email,
          dob: output.user.dob,
          verify: output.user.verify,
          emailNotification: output.user.emailNotification,
          pushNotification: output.user.pushNotification,
          kycDocument: output.user.kycDocument,
          token: output.user.token,
          resetPasswordToken: output.user.resetPasswordToken,
          resetPasswordExpiresAt: output.user.resetPasswordExpiresAt,
        })
      );

      navigate("/dashboard");
    } else {
      console.log(output.message);
    }
  };

  return (
    <div className="login-wraper" style={{ height: "100vh" }}>
      <div className="login-wraper-in">
        <div className="login-left">
          <figure
            style={{
              height: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <img src="assets/images/logo-coin.png" alt="Logo" />
          </figure>
          <figcaption>
            <h4>Empower users to buy crypto</h4>
            <p>
              It is a long established fact that a reader will be distracted by
              the readable content of a page when looking at its layout.{" "}
            </p>
          </figcaption>
        </div>
        <div className="login-right">
          <div className="login-right-in">
            <div className="login-head">
              <a
                onClick={() => {
                  navigate("/");
                }}
                style={{
                  cursor: "pointer",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/logo-white.svg"}
                  alt="Logo"
                  width={250}
                  height={80}
                />
              </a>
              <div className="login-head-text">
                <h4>Login to your account.</h4>
                <p>Please enter your information to login </p>
              </div>
            </div>
            <div className="login-body">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="form-box">
                  <div className="form-group form-gry">
                    <label>Email Address</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-user"></i>
                      </div>
                      <input
                        type="text"
                        maxLength={50}
                        className="form-control"
                        placeholder="Enter email address"
                        {...register("email", {
                          required: true,
                          pattern: {
                            value:
                              /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
                            message: "Enter a valid email address",
                          },
                        })}
                      />
                    </div>
                    {errors.email && errors.email.type === "required" && (
                      <small className="text-danger">Email is required</small>
                    )}
                    {errors.email && errors.email.type === "pattern" && (
                      <small className="text-danger">
                        {errors.email.message}
                      </small>
                    )}
                  </div>

                  <div className="form-group form-gry password-view-input">
                    <label>Password</label>
                    <div className="input-group">
                      <div className="input-group-icon">
                        <i className="ti ti-lock"></i>
                      </div>
                      <input
                        type={showPass ? "text" : "password"}
                        className="form-control"
                        placeholder="Enter password"
                        {...register("password", { required: true })}
                      />
                      <div
                        style={{
                          position: "absolute",
                          right: "14px",
                          top: "7px",
                          fontSize: "24px",
                          color: "var(--white)",
                          zIndex: 9,
                          background: "transparent",
                        }}
                        onClick={togglePasswordVisibility}
                      >
                        <i
                          className={`ti ti-eye${!showPass ? "-off" : ""}`}
                        ></i>
                      </div>
                    </div>
                    {errors.password && (
                      <small className="text-danger">
                        Password is required
                      </small>
                    )}
                  </div>
                  <div className="remember-me-box d-flex justify-content-between align-items-center mb-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        // {...register("remember", { required: true })}
                      />
                      <label
                        className={`form-check-label ${
                          false ? "text-danger" : ""
                        }`}
                        htmlFor="flexCheckDefault"
                      >
                        Remember me
                      </label>
                    </div>

                    <a
                      className="link-text"
                      onClick={() => {
                        navigate("/fotgot-password");
                      }}
                      style={{
                        cursor: "pointer",
                      }}
                    >
                      Forgot password?
                    </a>
                  </div>

                  <div className="form-btn">
                    <button type="submit" className="btn">
                      Login
                    </button>
                  </div>
                  <div className="last-line mt-3 text-center">
                    <span>
                      Don’t have an account?{" "}
                      <a
                        onClick={handleSignup}
                        className="link-text"
                        style={{
                          cursor: "pointer",
                        }}
                      >
                        Register
                      </a>
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
